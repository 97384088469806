import React, {useState} from "react";
import { Checkbox, DateRangePicker } from "rsuite";
import OverrideForm from "../../components/Override/Form";
import FilterForm from "../../components/base/FilterForm";
import { debounce, parseOptionPosition } from "../../utils";
import { withTranslation } from "../../hoc/WithTranslation";
import { withMobile } from "../../hoc/WithMobile";


const CDRFilter = ({
    value,
    setValue,

    applyFilters,
    
    options,

    isMobile,
    isIssueWidth,

    m,
    formatMessage
}) => {

    const formFields = [
        {
            name: "date",
            accepter: DateRangePicker,
            showOneCalendar: isMobile,
            cleanable: false,
            width: 240
        },
        {
            name: "filter_cli",
            placeholder: `${formatMessage(m.aNumber)}...`,
            width: 240
        },
        {
            name: "filter_phone",
            placeholder: `${formatMessage(m.bNumber)}...`,
            width: 240
        }
    ];


    return (
        <OverrideForm
            formDefaultValue={value}
            onChange={debounce((formValue) => {
                setValue((value) => {
                    return {
                        ...value,
                        ...formValue
                    }
                });
            }, 200)}
            fields={formFields}
            component={FilterForm}  
            options={options}
            // component props
            position={parseOptionPosition(options)}
            applyFunc={applyFilters}
            applyButton

            isIssueWidth={isIssueWidth}
        >
        </OverrideForm>
    )
};

export default withMobile(withTranslation(CDRFilter), {mobile: 620, issueWidth: 1300});