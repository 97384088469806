import {defineMessages} from "react-intl";

export default defineMessages({
    myNumbers: {
      id: "menu.myNumbers",
      defaultMessage: "My Numbers",
      description: "Menu: My Numbers",
    },
    testCalls: {
      defaultMessage: "Test calls",
      description: "Menu: Test calls",
      id: "menu.testCalls"
    },
    liveCalls: {
      defaultMessage: "Live calls",
      description: "Menu: Live calls",
      id: "menu.liveCalls"
    },
    report: {
      defaultMessage: "Report",
      description: "Menu: Report",
      id: "menu.report"
    },
    ratesAndNumbers: {
      defaultMessage: "Ratecard",
      description: "Menu: Ratecard",
      id: "menu.ratesAndNumbers"
    },
    hotAccessList: {
      defaultMessage: "Hot Access List",
      description: "Menu: Hot Access List",
      id: "menu.hotAccessList"
    },
    accessList: {
      defaultMessage: "Access List",
      description: "Menu: Access List",
      id: "menu.accessList"
    },
    accesses: {
      defaultMessage: "Accesses",
      description: "Menu: Accesses",
      id: "menu.accesses"
    },
    cliLookup: {
      defaultMessage: "CLI Lookup",
      description: "Menu: CLI Lookup",
      id: "menu.cliLookup"
    },
    editProfile: {
      defaultMessage: "Edit profile",
      description: "Menu: Edit profile",
      id: "menu.editProfile"
    },
    ivr: {
      defaultMessage: "IVR",
      description: "Menu: IVR",
      id: "menu.ivr"
    },
    logout: {
      defaultMessage: "Logout",
      description: "Menu: Logout",
      id: "menu.logout"
    },
    unlogin: {
      defaultMessage: "Log Out",
      description: "Menu: Log Out",
      id: "menu.unlogin"
    },
    menu: {
      defaultMessage: "Menu",
      description: "common: Menu",
      id: "menu.menu"
    },
    yourAccountManager: {
      defaultMessage: "Your account manager",
      description: "Menu: Your account manager",
      id: "menu.yourAccountManager"
    },
    payments: {
      defaultMessage: "Payments",
      description: "Common: Payments",
      id: "menu.payments"
    },
    gaNumbers: {
      defaultMessage: "GA Numbers",
      description: "Menu: GA Numbers",
      id: "menu.gaNumbers"
    },
    other: {
      defaultMessage: "Other",
      description: "Menu: Other",
      id: "menu.other"
    }
});