import {getFileResponse} from "../api/loginRoutes";
import {downloadDataAsFile} from "../utils/helpers";

export const getFileFromApi = async (method, params, fileName="file.csv", callback=null) => {
    await getFileResponse(method, params, "csv")
        .then((response) => {
            downloadDataAsFile(response, fileName);
        })
        .finally(() => {
            if (callback) {
                callback();
            }
        });

    return;
};

