import React, {useMemo} from "react";
// import {Table} from "rsuite";
import OverrideTable from "../../components/Override/Table";
import BaseTable from "../../components/base/BaseTable";

import { withTranslation } from "../../hoc/WithTranslation";
import { withMobile } from "../../hoc/WithMobile";
import { mobileColumnAlign } from "../../utils";


const TrafficReportTable = ({
    data,
    count,
    page,
    perPage,
    dataLoading, 
    options,
    filter,

    exportFunc,
    getItems,

    isMobile,
    isTablet,
    isIssueWidth,

    m,
    formatMessage
}) => {
    const columns = useMemo(() => {
        const trafficReportColumns = [
            {
                id: "cli",
                label: formatMessage(m.cli),
                dataKey: "cli",
                align: mobileColumnAlign(isMobile),
                flexGrow: 1,
                minWidth: 120,
                showOnGroup: "cli"
            },
            {
                id: "phone",
                label: formatMessage(m.phone),
                dataKey: "phone",
                align: mobileColumnAlign(isMobile),
                flexGrow: 1,
                minWidth: 120,
                showOnGroup: "phone"
            },
            {
                id: "price",
                label: formatMessage(m.price),
                dataKey: "rate",
                value: ({rate, currency}) => {
                    return `${rate} ${currency}`;
                },
                align: mobileColumnAlign(isMobile),
                flexGrow: 1,
                minWidth: 100,
                showOnGroup: "price"
            },
            {
                id: "duration",
                label: formatMessage(m.minDuration),
                dataKey: "duration_sum",
                align: mobileColumnAlign(isMobile),
                flexGrow: 1,
                minWidth: 80,
            },
            {
                id: "cost",
                label: formatMessage(m.cost),
                dataKey: "cost_sum",
                align: mobileColumnAlign(isMobile),
                flexGrow: 1,
                minWidth: 100
            },
            {
                id: "calls",
                label: formatMessage(m.calls),
                dataKey: "calls",
                align: mobileColumnAlign(isMobile),
                flexGrow: 1,
                minWidth: 80
            }
        ];

        const filteredColumns = trafficReportColumns.filter((column) => {
            if ("showOnGroup" in column) {
                if (filter.group 
                    && filter.group.length
                    && filter.group.includes(column.showOnGroup)
                ) {
                    return true;
                }

                return false;
            }

            return true;
        });

        return filteredColumns;

    }, [data, filter])
   

    return (
        <OverrideTable
            component={BaseTable}
            columns={columns}
            options={options}
            loading={dataLoading}

            height={440}
            rowHeight={40}

            data={data}
            count={count}
            page={page}
            perPage={perPage}
            pagination

            export={exportFunc}

            getItems={getItems}

            isMobile={isMobile}
            isTablet={isTablet}
            isIssueWidth={isIssueWidth}

        />
    );
};

export default withMobile(withTranslation(TrafficReportTable), {issueWidth: 1000, tablet: 767, mobile: 500});