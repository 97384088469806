import styled, {css} from "styled-components";


export default styled.div.attrs(props => ({
    className: props.className || "override-table-footer"
}))`
    && {
        ${props => {
            return props.pagination ? css`

            ` : css`

            `}
        }
    }

    && .rs-pagination-group-total span:first-child {
        ${props => props.isTablet ? css`
            display: none;
        ` : css`
            display: inline;
        `}
    }

    && .fluid-flexbox-grid-left {
        ${props => props.isMobile ? css`
            display: block;
            width: 100%;
        ` : css`
            display: inline-block;
        `}
    }

    && .fluid-flexbox-grid-right {
        ${props => props.isMobile ? css`
            margin-left: 0;
            margin-top: 20px;

            width: 100%;

            .footer-buttons, button {
                width: 100%;
            }
        ` : css`
            margin-left: auto;
        `}

        
    }
`;