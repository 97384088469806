
// API Methods
// access list
export const ACCESS_LIST_GET_LIST_METHOD = "access_list";

// balance
export const BALANCE_METHOD = "balance";

// cdr
export const CDR_GET_LIST_METHOD = "cdr_list";

// livecalls
export const LIVE_CALL_GET_LIST_METHOD = "live_call_list";

// payments
export const PAYMENTS_GET_LIST_METHOD = "payment_list";
export const TRAFFIC_GET_LIST_METHOD = "traffic_stat_list";

// traffic report
export const TRAFFIC_REPORT_GET_LIST_METHOD = "cdr_report_list";

// numbers
export const NUMBERS_GET_LIST_METHOD = "number_list";

// auth
// login
export const LOGIN_METHOD = "login";
// logout
export const LOGOUT_METHOD = "logout";