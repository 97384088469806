import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import PanelLayout from "../../components/Override/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import Title from "../../components/Override/Title";

import MyNumbersFilter from "./MyNumbersFilter";
import MyNumbersTable from "./MyNumbersTable";
import {getNumbersList} from "../../actions/numbers";

import {withRouter} from "../../hoc/WithRouterHOC";

import {getOptionParams, objectClearEmptyValues, getServiceByLocation} from "../../utils";


const defaultFilterValue = {
    filter_phone: null
};

const MyNumbers = ({
    // api data
    dataList,
    count,
    page,
    perPage,
    // api methods
    getNumbersList,
    // storage data
    dataLoading,
    // other data
    header,
    options,
    location
}) => {
    Title(header);

    const tableOptions = getOptionParams(options, location, "table");
    const filterOptions = getOptionParams(options, location, "filter");

    const serviceByLocation = getServiceByLocation(location);
    const [filter, setFilter] = useState(defaultFilterValue);

    useEffect(() => {
        update();
    }, [serviceByLocation, filter]);

    const getItems = (_page, _perPage) => {
        const clearFilter = objectClearEmptyValues(filter);
        getNumbersList(serviceByLocation, clearFilter, _page, _perPage);
    };

    const onExport = () => {
        update(true);
    };

    const update = (csv=false) => {
        const clearFilter = objectClearEmptyValues(filter);
        const csvFileName = csv ? "numbers.csv" : null;
        getNumbersList(serviceByLocation, clearFilter,  1, perPage, csvFileName);
    };

    return <PanelLayout>
        <PageHeader title={header}></PageHeader>
        <MyNumbersFilter
            value={filter}
            setValue={setFilter}
            options={filterOptions}
        />
        <MyNumbersTable
            data={dataList}
            count={count}
            page={page}
            perPage={perPage}
            options={tableOptions}
            dataLoading={dataLoading}

            exportFunc={onExport}
            getItems={getItems}
        />
    </PanelLayout>
};


const mapState = ({numbers, references}) => ({
    dataList: numbers.list,
    count: numbers.count,
    page: numbers.page,
    perPage: numbers.perPage,
    dataLoading: numbers.loading,
    options: references.options["pages"]
});

const mapMethods = {
    getNumbersList
};

export default withRouter(connect(mapState, mapMethods)(MyNumbers));