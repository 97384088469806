import React from "react";
import {Routes, Route, Navigate} from "react-router";
import RouteWrapper from "../components/Router/RouteWrapper";
import {
    DEFAULT_AUTH_PATH, DEFAULT_NOT_AUTH_PATH, DEFAULT_TITLE_SET,
    transformed_service_data
} from "../const";


import Login from "../pages/Login/LoginPage";

import LiveCalls from "../pages/LiveCalls/LiveCallsPage";
import MyNumbers from "../pages/MyNumbers/MyNumbersPage";
import TrafficReport from "../pages/TrafficReport/TrafficReportPage";
import CDR from "../pages/CDR/CDRPage";
import AccessList from "../pages/AccessList/AccessListPage";
import Payments from "../pages/Payments/PaymentsPage";
import { withTranslation } from "../hoc/WithTranslation";
import { isBoolean, getOption } from "../utils";


export default withTranslation(({auth, options, m, formatMessage}) => {
    const sidebarOptions = getOption(options, "sidebar");
    const titleSet = sidebarOptions.titleSet || DEFAULT_TITLE_SET;

    const titleSetList = {
        1: {
            "voice": {
                "title": formatMessage(m.calls),
                "myNumbers": formatMessage(m.myNumbers),
                "trafficReport": formatMessage(m.trafficReport),
                "cdr": formatMessage(m.cdr),
                "accessList": formatMessage(m.accessList)
            },
            "sms": {
                "title": formatMessage(m.sms),
                "myNumbers": formatMessage(m.myNumbers),
                "trafficReport": formatMessage(m.trafficReport),
                "cdr": formatMessage(m.mdr),
                "accessList": formatMessage(m.accessList)
            },
            "liveCalls": formatMessage(m.liveCalls),
            "payments": formatMessage(m.payments)
        },
        2: {
            "voice": {
                "title": `Second ${formatMessage(m.calls)}`,
                "liveCalls": `Second ${formatMessage(m.liveCalls)}`,
                "myNumbers": `Second ${formatMessage(m.myNumbers)}`,
                "trafficReport": `Second ${formatMessage(m.trafficReport)}`,
                "cdr": `Second ${formatMessage(m.cdr)}`,
                "accessList": `Second ${formatMessage(m.accessList)}`
            },
            "sms": {
                "title": `Second ${formatMessage(m.sms)}`,
                "myNumbers": `Second ${formatMessage(m.myNumbers)}`,
                "trafficReport": `Second ${formatMessage(m.trafficReport)}`,
                "cdr": `Second ${formatMessage(m.mdr)}`,
                "accessList": `Second ${formatMessage(m.accessList)}`
            },
            "payments": `Second ${formatMessage(m.payments)}`
        }
    };

    const getTitleNames = (setNameList, setName, withService=false) => {
        const titleNamesDict = setNameList[setName];

        return Object.keys(titleNamesDict).reduce((result, key) => {
            if (isBoolean(withService) && !!withService && ["voice", "sms"].includes(key)) {
                const serviceTitleNameDict = Object.keys(titleNamesDict[key]).reduce((serviceResult, serviceKey) => {
                    serviceResult[serviceKey] = `${titleNamesDict[key].title} ${titleNamesDict[key][serviceKey]}`;
                    
                    return serviceResult;
                }, {});

                result[key] = serviceTitleNameDict;

                return result;
            }

            result[key] = titleNamesDict[key];

            return result;
        }, {});
    };

    const menuText = getTitleNames(titleSetList, titleSet);
    const routeText =  getTitleNames(titleSetList, titleSet, true); // not finished

    return <Routes>
        {!auth 
        ? <Route path="*" element={<Navigate to={DEFAULT_NOT_AUTH_PATH}/>}/>
        : <Route path="*" element={<Navigate to={DEFAULT_AUTH_PATH}/>}/>}
        <Route path="/login" element={<Login/>}/>
        <Route element={<RouteWrapper auth={auth} menuText={menuText}/>}>
            <Route exact path="/live-calls" element={<LiveCalls header={routeText.liveCalls}/>}/>
            <Route exact path="/my-numbers-voice" element={<MyNumbers header={routeText.voice.myNumbers}/>}/>
            <Route exact path="/traffic-report-voice" element={<TrafficReport header={routeText.voice.trafficReport}/>}/>
            <Route exact path="/cdr" element={<CDR header={routeText.voice.cdr}/>}/>
            <Route exact path="/access-list-voice" element={<AccessList header={routeText.voice.accessList}/>}/>
            {/* <Route exact path="/my-numbers-sms" element={<MyNumbers header={formatMessage(m.myNumbers, false)}/>}/>
            <Route exact path="/traffic-report-sms" element={<TrafficReport header={formatMessage(m.trafficReport, false)}/>}/>
            <Route exact path="/mdr" element={<CDR header={formatMessage(m.mdr, false)}/>}/>
            <Route exact path="/access-list-sms" element={<AccessList header={formatMessage(m.accessList, false)}/>}/> */}
            <Route exact path="/payments" element={<Payments header={routeText.payments}/>}/>
        </Route>
    </Routes>;
});