import {loginAxios, requestAxios} from ".";
import {appendGetParameters} from "../utils";

import packageJson from "../../package.json";
import { LOGIN_METHOD, LOGOUT_METHOD } from "../const";

const appVersion = "sub_dialer/" + packageJson.service;


export const login = async (data) => {
    const requestUrl = appendGetParameters(LOGIN_METHOD, data);

    return await loginAxios.get(requestUrl,
        {
            headers: {
                "X-App-Version": appVersion,
            }
        }
    )
    .then(response => response)
    .catch(e => e)
};


export const logout = async () => {
    return await loginAxios.get(LOGOUT_METHOD,
        {
            headers: {
                "X-App-Version": appVersion,
            }
        }
    )
    .then(response => response)
    .catch(e => false)
};
