import React from "react";
import {Panel, Content} from "rsuite";
import styled, {css} from "styled-components";


export const PositionedContent = styled(Content).attrs(props => ({
    className: props.className || "override-login-content"
}))`
    &&& {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
    }
`;

export const StyledPanel = styled(Panel).attrs(props => ({
    className: props.className || "override-login-panel",
    ...props
}))`
    &&& {
        max-width: 500px;
        min-width: 340px;
        width: 100%;
        border: ${props => props.optionsExists && props.options.bordered 
            ? "1px solid var(--color-login-panel-border)"
            : "none"
        };
    }

    &&& .rs-form {
        padding-top: 20px;
    }

    &&& .rs-panel-header {
        background: ${props => props.optionsExists && props.options.header.filled 
            ? "var(--color-login-panel-header-bg)" 
            : "transparent"
        };
    }

    &&& .rs-panel-header .rs-panel-title {
        text-align: ${props => props.optionsExists && props.options.header.centered 
            ? "center" 
            : "inherit"
        }; 
        color: ${props => props.optionsExists && props.options.header.filled 
            ? "var(--color-login-panel-header-color-filled)" 
            : "var(--color-login-panel-header-color)"
        };
    }
`;