import React from "react";
import {Button as RSButton, Whisper, Tooltip} from "rsuite";
import {Icon} from "../Icon";
import styled, {css} from "styled-components";


const Button_ = ({tooltipText, tooltipPlacement = "auto", children, disabled, className, ...props}) => {
    if (tooltipText) {
        const classNameUpdated = disabled ? `${className} rs-btn-disabled` : className;
        return <Whisper
            speaker={<Tooltip>{tooltipText}</Tooltip>}
            placement={tooltipPlacement}

        >
            <RSButton
                className={classNameUpdated}
                {...props}
            >
                {children}
            </RSButton>
        </Whisper>
    }

    return <RSButton disabled={disabled} className={className} {...props}>{children}</RSButton>;
};

const Button = styled(Button_).attrs(props => ({
    buttonStyle_: props.buttonStyle || "default",
    appearance: props.buttonStyle
}))`
    color: var(--color-button-text-default);
    background-color: var( --color-button-bg-default);
    border-color: var( --color-button-bg-default);
    height: ${props => props.height || 36} + "px";

    &:focus,
    &:hover,
    &:focus:hover,
    &:active:hover,
    &:active:focus, 
    &:active.focus {
        background-color: var(--color-button-bg-default-hover);
        border-color: var(--color-button-bg-default-hover);
    }

    ${props => {
        switch(props.buttonStyle) {
            case "default":
                return css`
                    color: var(--color-button-text-default);
                    background-color: var( --color-button-bg-default);
                    border-color: var( --color-button-bg-default);

                    &:focus,
                    &:hover,
                    &:focus:hover,
                    &:active:hover,
                    &:active:focus, 
                    &:active.focus {
                        color: var(--color-button-text-default);
                        background-color: var(--color-button-bg-default-hover);
                        border-color: var(--color-button-bg-default-hover);
                    }
                `;
            case "ghost":
                return css`
                    color: var(--color-button-bg-default);
                    background-color: transparent;
                    border-color: var(--color-button-bg-default);
                    box-shadow: none;

                    &:focus,
                    &:hover,
                    &:focus:hover,
                    &:active:hover,
                    &:active:focus, 
                    &:active.focus {
                        color: var(--color-button-bg-default);
                        background-color: transparent;
                        border-color: var(--color-button-bg-default-hover);
                        box-shadow: 0 0 0 1px var(--color-button-bg-default-hover);
                    }
                `;
            case "link":
                return css`
                    color: var(--color-button-bg-default);
                    background-color: transparent;
                    border-color: transparent;

                    &:focus,
                    &:hover,
                    &:focus:hover,
                    &:active:hover,
                    &:active:focus, 
                    &:active.focus {
                        color: var(--color-button-bg-default-hover);
                        background-color: transparent;
                        border-color: transparent;
                    }

                    &:active,
                    &:visited {
                        color: var(--color-button-bg-default-hover);
                        text-decoration: underline;
                    }
                `;
            case "substle":
                return css`
                    color: var(--color-text-color-dark);
                    background-color: transparent;
                    border-color: transparent;

                    &:focus,
                    &:hover,
                    &:focus:hover,
                    &:active:hover,
                    &:active:focus, 
                    &:active.focus {
                        color: var(--color-button-text-default);
                        background-color: var(--color-main-background-dark);
                        border-color: var(--color-main-background-dark);
                    }
                `;
            case "danger":
                return css`
                    color: var(--color-button-text-default);
                    background-color: var(--color-button-bg-danger);
                    border-color: var(--color-button-bg-danger);

                    &:focus,
                    &:hover,
                    &:focus:hover,
                    &:active:hover,
                    &:active:focus, 
                    &:active.focus {
                        color: var(--color-button-text-default);
                        background-color: var(--color-button-bg-danger-hover);
                        border-color:  var(--color-button-bg-danger-hover);
                    }
                `;
            case "warning":
                return css`
                    color: var(--color-button-text-default);
                    background-color: var(--color-button-bg-warning);
                    border-color: var(--color-button-bg-warning);

                    &:focus,
                    &:hover,
                    &:focus:hover,
                    &:active:hover,
                    &:active:focus, 
                    &:active.focus {
                        color: var(--color-button-text-default);
                        background-color: var(--color-button-bg-warning-hover);
                        border-color:  var(--color-button-bg-warning-hover);
                    }
                `;
            case "success":
                return css`
                    color: var(--color-button-text-default);
                    background-color: var(--color-button-bg-success);
                    border-color: var(--color-button-bg-success);

                    &:focus,
                    &:hover,
                    &:focus:hover,
                    &:active:hover,
                    &:active:focus, 
                    &:active.focus {
                        color: var(--color-button-text-default);
                        background-color: var(--color-button-bg-success-hover);
                        border-color:  var(--color-button-bg-success-hover);
                    }
                `;
        }
    }}

    &.rs-btn-disabled,
    &:disabled,
    &:disabled:hover,
    &:disabled:hover,
    &:disabled:focus, 
    &:disabled.focus {
        color: var(--color-main-background-dark);
        background-color: var(--color-background-disabled);
        border-color: var(--color-background-disabled);
        opacity: 0.3;
        box-shadow: none;
        text-decoration: none;
    }
`;

export default Button;