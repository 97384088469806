import axios from "axios";
import store from "../store/index";
import history from "../config/history";
import {setAuth, setServiceAction, setMaintenanceMode} from "../actions/auth";
import {
    ERROR_CODE_TOO_MUCH_OPERATIONS, 
    ERROR_CODES,
    // api methods
    BALANCE_METHOD,
    // local store
    LOCAL_STORAGE_SESSION_EXPIRED,
} from "../const";

const localHosts = ["localhost:3000", "localhost:3001", "localhost:3002"];

const domain = `https://${window.location.host}/api`;
const localDomain = process.env.REACT_APP_API_URL;
export const baseUrl = localHosts.includes(window.location.host) ? localDomain : domain;


const _requestAxios = (headers = false) => {
    // const state = store.getState();

    const url = headers ? `${baseUrl}/headers` : baseUrl;
    let instanceAxios = axios.create({
        baseURL: url,
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 3600000
    });
    /* Request Interceptor */
    instanceAxios.interceptors.request.use(function (config) {
        const session_id = localStorage.getItem("sessionId");

        if(session_id) {
            config.baseURL = url;
            config.headers = {...config.headers, "Session-ID": session_id}
        } else {
            // const isExpired = ![BALANCE_METHOD].includes(config.data.method);
            unsetAuth(true);
        }

        return config;

    }, function (error) {
        return Promise.reject(error);
    });


    /* Response Interceptor */
    instanceAxios.interceptors.response.use(function (response) {
        // const responseError = _.get(response, "data.error");
        
        // CATPCHA ERROR HANDLER

        // const responseError = response?.data?.error;
        // const isCaptchaError = response.status === 403 && responseError.data === "need captcha_token";

        // if (responseError) {
        //     if (responseError.code === 1 || responseError.code === 2) {
        //         if (isCaptchaError) {
        //             return null;
        //         }
                
        //         unsetAuth();
        //     } else {
        //         // Alert.error(ERROR_CODES[responseError.code] || ERROR_CODES.DEFAULT);
        //     }
        //     return null;
        // }
        return response;

    }, function (error) {

        // on cancelled request
        if ( axios.isCancel(error) ) {
            return error;
        }

        const {response} = error;
        
        const responseErrorBody = response?.data;
        const isCaptchaError = response.status === 403 && responseErrorBody.data &&
            responseErrorBody.data.error_type.includes("captcha_token");
        const sessionExpiredError = response.status === 401 && responseErrorBody.data &&
            responseErrorBody.data.error_type.includes("session_key");
        
        if (response.status === 423) {
            if (responseErrorBody?.code === ERROR_CODE_TOO_MUCH_OPERATIONS) {
                // Alert.error("Too much simultaneous operations")
                return null;
            } 
            // Alert.error("Operation failed. Try again in few seconds");
            return null;
        }

        // on enabled maintenance
        if (response.status === 503) {
            store.dispatch( setMaintenanceMode(true) );
            return null;
        }

        // const responseError = _.get(response, "data.error");
        if (responseErrorBody) {
            if (sessionExpiredError) {
                unsetAuth(true);
                return null;
            }

            if (isCaptchaError) {
                const {config} = response;
                // RECAPTCHA (later)
                // const {method, params} = requestData;
                // if (method !== "account_user_login") {
                //     store.dispatch(setRequestData(method, params));
                //     store.dispatch(onVisibleRecaptcha());
                // }

                return null;
            }
        }

        return Promise.reject(error);

    });

    return instanceAxios;
};


const _loginAxios = () => {
    const instanceAxios = axios.create({
        baseURL: baseUrl,
        headers: {
            "Content-Type": "application/json"
        }
    });

    instanceAxios.interceptors.response.use(function (response) {

        if (response.data.error) {
            return null;
        }

        return response;
        
    }, function (error) {
        
        if (error.response.status === 423) {
            // Alert.error("Operation failed. Try again in few seconds");
            return null;
        }

        if (error.response.status === 503) {
            store.dispatch( setMaintenanceMode(true) );
            return null;
        }

        return Promise.reject(error);
    });

    return instanceAxios;
};



const _checkVersionAxios = () => {
    const instanceAxios = axios.create({
        headers: {
            "Content-Type": "application/json"
        }
    });

    instanceAxios.interceptors.response.use(
        function (response) {
            if (response.data.error || response.status === 404) {
                return null;
            }
            return response;
        }, function (error) {
            return Promise.reject(error);
        }
    );

    return instanceAxios;
};

const unsetAuth = (session_expired=false) => {
    store.dispatch( setAuth(false) );
    store.dispatch( setServiceAction(true) );
    // localStorage.removeItem("SMS_SERVICE");
    
    if (session_expired && !localStorage.getItem(LOCAL_STORAGE_SESSION_EXPIRED)) {
        localStorage.setItem(LOCAL_STORAGE_SESSION_EXPIRED, true);
        history.go();
        history.replace("/login");
    }

    if (localStorage.getItem("sessionId")) {
        localStorage.removeItem("sessionId");
        //             history.goForward();
    }

};

export const loginAxios = _loginAxios();
export const requestAxios = _requestAxios();
export const requestAxiosWithHeaders = _requestAxios(true);
export const checkVersionAxios = _checkVersionAxios();