import React, { useRef, useMemo, useState, useEffect } from "react"
import _ from "lodash";
import {connect} from "react-redux";
import {withRouter} from "../../hoc/WithRouterHOC";
import {
    Button, Form, Schema
} from "rsuite";
import {useNavigate} from "react-router";

import {loginUser, loginBySession, changeLanguage} from "../../actions/auth";
import {getOverrideOptions} from "../../actions/references";
import {clearLocalStorage, getOptionParams, parseFormRefRoot} from "../../utils/helpers";
import {RtlContext} from "../../App";

import LangSwitcher from "../../components/LangSwitcher";
import {Icon} from "../../components/Icon";
import {FaEye, FaEyeSlash} from "react-icons/fa";
import LoginPanel from "../../components/Override/LoginPanel";

import styled from "styled-components";
import { DEFAULT_AUTH_PATH } from "../../const";


const {StringType} = Schema.Types;


export const loginModel = Schema.Model({
    email: StringType().isRequired("Required"),
    password: StringType().isRequired("Required"),
});


const Login = (
    {
        loading,
        secondAuthType,
        secondAuthLink,
        secondAuthEmail,
        location,
        options,
        history,
        lang,

        loginUser,
        loginBySession,
    }
) => {
    const formRef = useRef();
    const formData = useRef(null);

    const navigate = useNavigate();

    const recaptchaRef = useRef(null);
    const [recapchaIsVisible] = useState(() => {
        return process.env.REACT_APP_IS_PRODUCTION 
            ? !!(+process.env.REACT_APP_IS_PRODUCTION)
            : false
    });
    const [showPassword, onChangeShowPassword] = useState(true);
    const [language, setLanguage] = useState(lang);

    const sessionId = useMemo(() => {
        return new URLSearchParams(location.search).get("session-id") || null;
    }, [location, location.search]);

    useEffect(() => {
        document.body.classList.remove("rtl");
        // localStorage.removeItem("SMS_SERVICE");
    }, []);


    useEffect(() => {
        return () => resetRecaptcha();
    });

    useEffect(() => {
        const storagedSessionId = localStorage.getItem("sessionId");

        if (storagedSessionId) {
            localStorage.removeItem("sessionId");
        }

        if (sessionId) {
            loginBySession(sessionId, () => {
                navigate(DEFAULT_AUTH_PATH);
            });
        }
    }, [sessionId, location.path]);


    const submit = () => {
        if (!formRef.current.check()) {
            resetRecaptcha();
            return;
        }

        if (formRef.current) {
            // ref has no getFormValue now
            formData.current = parseFormRefRoot(formRef, ["email", "password"])
            executeRecaptcha();
        }
    };

    const executeRecaptcha = () => {
        if (!recapchaIsVisible) {
            handleResolveCaptcha();
            return;
        }

        if (!recaptchaRef?.current?.execute) {
            return;    
        }

        recaptchaRef.current.execute();
    };

    const resetRecaptcha = () => {
        if (!recapchaIsVisible) {
            return;
        }

        if (!recaptchaRef?.current?.reset) {
            return;
        }

        recaptchaRef.current.reset();
    };

    const handleResolveCaptcha = (token) => {
        if (!token && recapchaIsVisible) {
            // Alert.error("Bot verification failed")
            resetRecaptcha();
            return;
        }


        loginUser({
            ...formData.current, lang: language,
            // captcha_token: token,
            loginLocation: location, loginHistory: history
        })
            .then((r) => {
                localStorage.setItem("SAVED_LAST_LOGIN_EMAIL", formData.current.email)
                clearLocalStorage();
            })
            .finally(() => {
                resetRecaptcha();
            });
    };

    const savedEmail = localStorage.getItem("SAVED_LAST_LOGIN_EMAIL");
    const defaultValue = ({email: savedEmail, lang});

    const userLanguages = getOptionParams(options, location, "languages", null, []);
    const panelOptions = getOptionParams(options, location, "panel");
    const contentOptions = getOptionParams(options, location, "content");

    const headerText = panelOptions && panelOptions.header && panelOptions.header.text;

    return (
        <RtlContext.Provider value={false}>
            <Container>
                <LoginPanel
                    panelOptions={panelOptions}
                    contentOptions={contentOptions}
                    header={<h3>{headerText || "Login"}</h3>}
                >
                    <Form
                        fluid
                        ref={formRef}
                        formDefaultValue={defaultValue}
                        onChange={(value) => {
                            setLanguage(value?.lang);
                        }}
                        model={loginModel}
                    >
                        <Form.Group>
                            <Form.ControlLabel>Email address or login</Form.ControlLabel>
                            <Form.Control autoFocus={!savedEmail} name="email"/>
                        </Form.Group>

                        <Form.Group>
                            <Form.ControlLabel>Password</Form.ControlLabel>
                            <Form.Control
                                autoFocus={!!savedEmail}
                                name="password"
                                type={showPassword ? "password" : "text"}
                            />
                            <Button
                                onClick={() => onChangeShowPassword(!showPassword)}
                                style={{
                                    position: "absolute",
                                    marginLeft: "-38px",
                                    background: "transparent"
                                }}>
                                <Icon icon={showPassword ? FaEye : FaEyeSlash}/>
                            </Button>
                        </Form.Group>

                        
                        {userLanguages && userLanguages.length > 1 ? <Form.Group>
                            <Form.ControlLabel>Language</Form.ControlLabel>
                            <Form.Control
                                accepter={LangSwitcher}
                                name="lang"
                                menuClassName="no-rtl"
                                userLanguages={userLanguages}
                                onChange={(lang) => {
                                    changeLanguage(lang);
                                }}
                            />
                        </Form.Group> : <></>}
                

                        <Form.Group>
                            <Button
                                onClick={submit}
                                type="submit"
                                disabled={loading}
                            >
                                Sign in
                            </Button>                     
                        </Form.Group>
                    </Form>
                </LoginPanel>
            </Container>
        </RtlContext.Provider>
    );
};

const mapState = ({auth, references}) => ({
    options: references.options,

    loading: auth.loading,
    secondAuthType: auth.secondAuthType,
    secondAuthLink: auth.secondAuthLink,
    secondAuthEmail: auth.secondAuthEmail,
    lang: auth.lang,
});

export default connect(mapState, {
    loginUser,
    loginBySession,
    changeLanguage,
    getOverrideOptions
})( withRouter(Login) );

const Container = styled.div`
    display: flex;
    min-height: 100vh;
`;