import React, {useState, useEffect} from "react";
import {APP_VERSION} from "../index";
import {verGreaterThan} from "../utils/helpers";
import {getAppVersion} from "../api/loginRoutes";
import {useWindowWidth} from "../hooks";
// import {CustomModal} from "./base/BaseModal";

const NEW_VERSION_AVAILABLE = "new_version_available";


export default () => {

    const [show, setShow] = useState(false);
    const resizedWidth = useWindowWidth();


    // check on mount
    useEffect( () => {
        checkVersion(true);
    }, [] );


    // interval check
    useEffect(() => {
        const interval = setInterval(() => {
            checkVersion();
        }, 120000);
        return () => clearInterval(interval);
    }, []);


    const checkVersion = (showCacheNotification) => {
        const storageVersion = localStorage.getItem(NEW_VERSION_AVAILABLE);

        getAppVersion().then( (meta) => {
            if (!meta)
                return;

            const isMetaVersionGreater = verGreaterThan(meta.version, APP_VERSION);

            if (!isMetaVersionGreater && storageVersion) {
                localStorage.removeItem(NEW_VERSION_AVAILABLE);
                return;
            }

            if (isMetaVersionGreater ) {

                if (storageVersion) {
                    if (storageVersion && storageVersion !== meta.version) {
                        localStorage.setItem(NEW_VERSION_AVAILABLE, meta.version);
                    }
                    if (showCacheNotification) {
                        // Alert.error(<>
                        //     <p>New version <b>{meta.version}</b> is available.</p>
                        //     <p>Please, clear cache and reload the page</p>
                        // </>, 300000000);
                    }
                    return;
                }

                setShow(true);
                localStorage.setItem(NEW_VERSION_AVAILABLE, meta.version);
            }
        } )
    };


    const handleClose = () => {
        setShow(false);
        window.location.reload();
    };


    return (
        <></>
        // <CustomModal
        //     show={show}
        //     onClose={handleClose}
        //     backdrop="static"
        //     showFooter={true}
        //     title={null}
        //     width={resizedWidth > 600 ? 600 : resizedWidth}
        //     customFooter={() => (
        //         <Button onClick={handleClose} appearance="primary">Ok</Button>
        //     )}
        // >
        //         <Icon
        //             icon="remind"
        //             style={{
        //                 color: "#ffb300",
        //                 fontSize: 24
        //             }}
        //         />
        //         &nbsp;&nbsp; The system has been updated, the page will be reloaded.
        // </CustomModal>
    );
};