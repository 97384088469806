import { 
    // actions
    SET_AUTH,
    SET_PERMISSION,
    SET_PERMISSION_ADD,
    SET_MAINTENANCE_MODE,
    SET_USER_LOCALIZATION
} from "../const";

const storagedSessionId = localStorage.getItem("sessionId");
const isAuth = storagedSessionId === "null" ? !!JSON.parse(storagedSessionId) : !!storagedSessionId;

const savedLang = localStorage.getItem("lang");
const savedRtl = localStorage.getItem("rtl") && JSON.parse( localStorage.getItem("rtl") ) ;

const initialState = {
    auth: isAuth,
    loading: false,
    permissions: [],
    permissions_add: [],

    maintenanceMode: false,
    lang: savedLang || "en",
    rtl: savedRtl || false
};

const handlers = {
    [SET_AUTH]: (state, {payload}) => ({
        ...state,
        auth: payload,
        loading: false,
        permissions: [],
        permissions_add: []
    }),
    [SET_PERMISSION]: (state, {payload}) => ({...state, permissions: payload}),
    [SET_PERMISSION_ADD]: (state, {payload}) => ({...state, permissions_add: payload}),
    [SET_MAINTENANCE_MODE]: (state, {payload}) => ({...state, maintenanceMode: payload}),
    [SET_USER_LOCALIZATION]: (state, {payload}) => ({...state, lang: payload.lang, rtl: payload.rtl}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};



