import React from "react";
import {Container, Tooltip, Whisper} from "rsuite";
import {currency_names_data, USD_DEFAULT_CURRENCY_KEY, EUR_DEFAULT_CURRENCY_KEY} from "../const";
import UserIcon from "@rsuite/icons/legacy/UserCircle";
import styled, {css} from "styled-components";

import { withTranslation } from "../hoc/WithTranslation";
import { withMobile } from "../hoc/WithMobile";

const ClientInfo = ({expand=true, balance = {}, leftToRight, m, formatMessage}) => {
    const username = balance.user_name || formatMessage(m.userName);
    const balanceUSD = balance?.balance_USD && balance.balance_USD.length ? balance.balance_USD[0].balance : null; 
    const balanceEUR = balance?.balance_EUR && balance.balance_EUR.length ? balance.balance_EUR[0].balance : null; 
    const balanceByCurrency = [
        ...(!!balanceUSD ? [{cur_key: USD_DEFAULT_CURRENCY_KEY, balance: balanceUSD}] : []),
        ...(!!balanceEUR ? [{cur_key: EUR_DEFAULT_CURRENCY_KEY, balance: balanceEUR}] : []),
    ];
    const period = balance.period || formatMessage(m.weekly);

    return expand ? <OverrideContainer
        className="override-user-info"
        expand={expand}
    >
        <span className="info-line">{username}</span>
        <div className="info-line balance">
            <span className="currency-header">{formatMessage(m.balance)}:</span>
            {balanceByCurrency.map((value) => {
                return <span className="currency-line">{currency_names_data[value.cur_key]}: {value.balance}</span>
            })}
        </div>
        <span className="info-line">{formatMessage(m.availableForPayment)}: {period}</span>
    </OverrideContainer> : <OverrideContainer
        className="override-user-info-mini"
    >
        <Whisper
            speaker={<Tooltip>
                <span className="info-line">{username}</span>
                <div className="info-line balance">
                    <span className="currency-header">{formatMessage(m.balance)}:</span>
                    {balanceByCurrency.map((value) => {
                        return <div className="currency-line">{currency_names_data[value.cur_key]}: {value.balance}</div>
                    })}
                </div>
                <span className="info-line">{formatMessage(m.availableForPayment)}: {period}</span>
            </Tooltip>
            }
            trigger="click"
            placement={!leftToRight ? "leftStart" : "rightStart"}
        >
            <UserIcon />
        </Whisper>
    </OverrideContainer>
};


export default withMobile(withTranslation(ClientInfo));


const OverrideContainer = styled(Container)`
    && {
        padding: 15px;
    }

    &&.override-user-info-mini {
        padding-left: 24px;
        padding-top: 20px;
        height: 50px;
    }

    
    &&.override-user-info-mini .rs-icon{
        width: 16px;
        height: 16px;
    }

    && .info-line {
        font-size: 1.1em;
        margin-bottom: 10px;
        ${props => props.expand ? css`
            visibility: visible;
            position: relative;
            /* display: block; */
            opacity: 1;
            transition: visibility 0s, opacity 1s cubic-bezier(.38,.02,0,.99);
        ` : css`
            display: none;
            visibility: hidden;
            position: absolute;
            /* display: none; */
            opacity: 0;
            transition: visibility 0s, opacity 0.2s linear;
        `}
    }
`;
