import {
    MIN_PER_PAGE_SMALL,
    CDR_SET_LIST,
    CDR_SET_LOADING
} from "../const";


const initialState = {
    list: [],
    count: 0,
    page: 1,
    perPage: MIN_PER_PAGE_SMALL,
    loading: false
};

const handlers = {
    [CDR_SET_LIST]: (state, {payload: {list, count, page, perPage}}) => ({
        ...state, 
        list,
        count,
        page,
        perPage
    }),
    [CDR_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};



