import React from "react";
import {FormControl, SelectPicker} from "rsuite";
import styled from "styled-components";

export default ({name, placeholder, userLanguages=[], ...props}) => {

    const langs = [
        {lang_key: "en", lang_name: "English"},
        {lang_key: "ar", lang_name: "اللغة العربية"},
        {lang_key: "ua", lang_name: "Українська"}
    ].filter(lang => userLanguages.includes(lang.lang_key));

    return (
        <Switcher
            name={name || "lang"}
            data={langs}
            labelKey="lang_name"
            valueKey="lang_key"
            placeholder={placeholder || "Select Language"}
            searchable={false}
            cleanable={false}
            {...props}
        />
    )
};

const Switcher = styled(SelectPicker) `
    && {
        width: 180px;
    }
`;