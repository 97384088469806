import {api, getFileResponse} from "../api/loginRoutes";
import {createAction} from "./defaults";

import {
    MIN_PER_PAGE_SMALL,
    NUMBERS_SET_LOADING,
    NUMBERS_SET_LIST
} from "../const";
import {
    NUMBERS_GET_LIST_METHOD
} from "../const/apiMethods";
import {getFileFromApi} from "../utils/apiHelpers";

const setLoading = createAction(NUMBERS_SET_LOADING);
const setNumbersList = createAction(NUMBERS_SET_LIST);


export const getNumbersList = (service, filter, page=1, perPage=MIN_PER_PAGE_SMALL, exportCsvName=null) => async (dispatch) => {
    dispatch(setLoading(true));

    const method = NUMBERS_GET_LIST_METHOD;

    const params = {
        ...filter,
        ...(!exportCsvName ? {
            page,
            per_page: perPage
        } : {})
    };


    if (exportCsvName) {
        return await getFileFromApi(method, params, exportCsvName, () => {
            dispatch(setLoading(false));
        });
    }

    await api(method, params).then((response) => {
        if (response.number_list) {
            dispatch(setNumbersList({
                list: response.number_list,
                count: response.number_count,
                page,
                perPage
            }));
        }
    })
    .finally(() => {
        dispatch(setLoading(false));
    })
};