import {api} from "../api/loginRoutes";
import {createAction} from "./defaults";

import {
    MIN_PER_PAGE_SMALL,
    ACCESS_LIST_SET_LIST,
    ACCESS_LIST_SET_LOADING
} from "../const";
import {
    ACCESS_LIST_GET_LIST_METHOD
} from "../const/apiMethods";
import {getFileFromApi} from "../utils/apiHelpers";

const setAccessListLoading = createAction(ACCESS_LIST_SET_LOADING);
const setAccessList = createAction(ACCESS_LIST_SET_LIST);


export const getAccessList = (filter, page=1, perPage=MIN_PER_PAGE_SMALL, exportCsvName=null) => async (dispatch) => {
    dispatch(setAccessListLoading(true));

    const method = ACCESS_LIST_GET_LIST_METHOD;

    const params = {
        ...filter,
        ...(!exportCsvName ? {
            page,
            per_page: perPage
        } : {})
    };

    if (exportCsvName) {
        return await getFileFromApi(method, params, exportCsvName, () => {
            dispatch(setAccessListLoading(false));
        });
    }

    await api(method, params).then((response) => {
        if (response.access_list_list) {
            dispatch(setAccessList({
                list: response.access_list_list,
                count: response.access_list_count,
                page,
                perPage
            }));
        }
    })
    .finally(() => {
        dispatch(setAccessListLoading(false));
    })
};
