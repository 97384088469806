
export const toUTCDateTime = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
};

export const toStartDayUTCTime = (date) => {
    return new Date(Date.UTC( date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0 ));
};

export const toEndDayUTCTime = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59));
};

export const toStartDayUTCTimeNextDay = (date) => {
    return new Date(Date.UTC( date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0 ));
};

export const toStartDayTime = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
};

export const toEndDayTime = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
};


export const getFormattedDate = (dateValue, separator=".") => {
    const date = new Date(dateValue);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${year}${separator}${month < 10 ? "0" + month : month}${separator}${day < 10 ? "0" + day : day}`;
};

export const addMonthDate = (date, addMonthTimes=3) => {
    return new Date(new Date(date).setMonth(new Date(date).getMonth() + addMonthTimes));
};

export const addDayDate = (date, addDayTimes=1) => {
    return new Date(new Date(date).setDate(new Date(date).getDate() + addDayTimes));
};

export const getClearDate = (date) => {
    return new Date(date).toISOString().replace(/.\d+Z*$/g, "").replace("T", " ");
};

export const getClearStrDate = (date) => {
    return date.replace(/.\d+Z*$/, "").replace("T", " ");
};

export const toStringDate = (date, sep=" ") => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}${sep}${hours}:${minutes}:${seconds}`;
}