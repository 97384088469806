import {defineMessages} from "react-intl";

export default defineMessages({
    aNumber: {id: "liveCalls.aNumber", defaultMessage: "A-number", description: "Live calls: A-number"},
    originatedFrom: {id: "liveCalls.originatedFrom", defaultMessage: "Originated from", description: "Live calls: Originated from"},
    bNumber: {id: "liveCalls.bNumber", defaultMessage: "B-number", description: "Live calls: B-number"},
    destination: {id: "liveCalls.destination", defaultMessage: "Destination", description: "Live calls: Destination"},
    range: {id: "liveCalls.range", defaultMessage: "Range", description: "Live calls: Range"},
    rateTerm: {id: "liveCalls.rateTerm", defaultMessage: "Rate term", description: "Live calls: Rate term"},
    duration: {id: "liveCalls.duration", defaultMessage: "Duration", description: "Live calls: Duration"},
    terminationPoint: {id: "liveCalls.terminationPoint", defaultMessage: "Termination point", description: "Live calls: Termination point"},
    termPoint: {id: "liveCalls.termPoint", defaultMessage: "Term point", description: "Live calls: Term point"},
    updateData: {id: "liveCalls.updateData", defaultMessage: "Update data", description: "Live calls: Update data"},
    filterPerNumOrDest: {
        id: "liveCalls.filterPerNumOrDest",
        defaultMessage: "Filter per numbers/destinations",
        description: "Live calls: Filter per numbers/destinations"
    },
    callingNumber: {
        id: "livecalls.callingNumber",
        defaultMessage: "Calling number",
        description: "Live Calls: Calling number"
    },
    calledNumber: {
        id: "livecalls.calledNumber",
        defaultMessage: "Called number",
        description: "Live Calls: Called number"
    }
})