import React from "react";
import { CheckboxGroup, Checkbox } from "rsuite";
import styled, {css} from "styled-components";

const BaseCheckboxGroup = ({title, name, children, ...props}) => {
    return (<StyledCheckboxGroup name={name} {...props}>
        {title ? <p className="title">{title}</p> : <></>}
        {children}
    </StyledCheckboxGroup>)
};

export default BaseCheckboxGroup;

const StyledCheckboxGroup = styled(CheckboxGroup)`

    ${props => props.inline && css`
        && {
            height: ${props => (props.height || 36) + "px"};

            display: flex;
            flex-direction: row;
            align-items: center;
        }

        && .title {
            padding-right: 10px;
        }
    `}
   
`;