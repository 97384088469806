import React from "rsuite";
import {Checkbox} from "rsuite";
import OverrideForm from "../../components/Override/Form";
import FilterForm from "../../components/base/FilterForm";
import { debounce } from "../../utils";
import { withTranslation } from "../../hoc/WithTranslation";


const MyNumbersFilter = ({
    value,
    setValue,

    options,

    m,
    formatMessage
}) => {

    const formFields = [
        {
            name: "filter_phone",
            placeholder: `${formatMessage(m.number)}...`,
            width: 180
        }
    ];
    

    return (
        <OverrideForm
            formDefaultValue={value}
            onChange={debounce((formValue) => {
                setValue((_value) => {
                    return {
                        ..._value,
                        ...formValue
                    }
                });
            }, 200)}
            fields={formFields}
            component={FilterForm}
            options={options}
        >
        </OverrideForm>
    )
};

export default withTranslation(MyNumbersFilter);