import React from "rsuite";
import {DateRangePicker} from "rsuite";
import OverrideForm from "../../../components/Override/Form";
import FilterForm from "../../../components/base/FilterForm";
import {debounce, parseOptionPosition} from "../../../utils";
import { withMobile } from "../../../hoc/WithMobile";


const TrafficFilter = ({
    value,
    setValue,

    isMobile,

    options
}) => {

    
    const formFields = [
        {
            name: "date",
            accepter: DateRangePicker,
            showOneCalendar: isMobile,
            cleanable: false,
            width: 240
        }
    ];

    return (
        <OverrideForm
            formDefaultValue={value}
            onChange={debounce((formValue) => {
                setValue((value) => {
                    return {
                        ...value,
                        ...formValue
                    }
                });
            }, 200)}
            fields={formFields}
            component={FilterForm}  
            options={options}
            // component props
            position={parseOptionPosition(options)}
        >
        </OverrideForm>
    )
};

export default withMobile(TrafficFilter, {mobile: 600});