import {Form} from "rsuite";
import styled, {css} from "styled-components";
import { ScreenPositions } from "../../const";


export default styled(Form).attrs(props => ({
    className: props.className || "override-filter-form"
}))`
    &&& {
        ${props => props.isAdaptive ? css`
            display: block;
        ` : css`
            display: flex;
            flex-direction: row;
            ${props => props.position === ScreenPositions.LEFT || !props.position ? css`
                justify-content: flex-start;
            ` : css`
                justify-content: flex-end;
            `}
        `}

       
    }
    &&& .rs-form-group {        
        margin-bottom: 20px;
        ${props => props.isAdaptive ? css`
            width: 100% !important;
        ` : css`
            width: 240px;
        `}
    }

    &&& .rs-checkbox-group {
        ${props => props.isAdaptive ? css`
            margin-bottom: 15px;
            margin-left: 5px;
        ` : css`
            margin-bottom: 0px;
            margin-left: 20px;
        `}
    }

    .rs-form-group:not(:first-child) {
        ${props => props.isAdaptive ? css`
            margin-left: 0px;
        ` : css`
            margin-left: 20px;
        `}
    }

    .rs-checkbox.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before {
        background-color: var(--color-form-checkbox-active);
        border-color:  var(--color-form-checkbox-active);
    }
    
    &&& .apply-button {
         ${props => props.position === ScreenPositions.LEFT || !props.position ? css`
            margin-left: auto;
        ` : css`
            margin-left: none;
        `}

        height: 36px;
        ${props => props.isAdaptive ? css`
            margin-bottom: 20px;
            width: 100%;
        ` : css`
            margin-bottom: 0px;
            width: 140px;
        `}
    }
`;