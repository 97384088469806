import {api} from "../api/loginRoutes";
import {createAction} from "./defaults";

import {
    LIVE_CALLS_SET_LOADING,
    LIVE_CALLS_SET_LIST
} from "../const";
import {
    LIVE_CALL_GET_LIST_METHOD
} from "../const/apiMethods";

const setLiveCallsLoading = createAction(LIVE_CALLS_SET_LOADING);
const setLiveCallsList = createAction(LIVE_CALLS_SET_LIST);


export const getLiveCallsList = (filter) => (dispatch) => {
    setLiveCallsLoading(true);

    api(LIVE_CALL_GET_LIST_METHOD, {...filter}).then((response) => {
        if (response.live_call_list) {
            dispatch(setLiveCallsList({list: response.live_call_list}));
        }
    })
    .finally(() => {
        setLiveCallsLoading(false);
    })
};
