import React from "react";
import {Container} from "rsuite";
import styled from "styled-components";

const PanelLayout = ({children}) => {
    return <OverrideContainer
        className="override-panel-layout"
    >
        {children}
    </OverrideContainer>
};

const OverrideContainer = styled(Container)`
    padding: 40px;
    padding-top: 100px;
    height: 100%;
    
    @media (max-width: 767px) {
        margin: 0;
        border-radius: 0;
        padding: 30px 15px 80px;
        /* width: 100%; */
    }
`;


export default PanelLayout;