import React, {useRef, useEffect, useLayoutEffect} from "react";
import {connect} from "react-redux";
import {Router, Route, Routes, Navigate, useLocation} from "react-router-dom";
import {useNavigate} from "react-router";
import CacheBooster from "./components/CacheBooster";
import {CustomProvider as RSIntlProvider} from "rsuite";
import {IntlProvider} from "react-intl";
import {ThemeProvider} from "styled-components";
import themeContext from "./context/themeContext";
import locales from "./locales";
import en from "rsuite/locales/en_US";
import ar from "rsuite/locales/ar_EG";
import {DEFAULT_AUTH_PATH, DEFAULT_NOT_AUTH_PATH} from "./const";
import PageRoutes from "./routes/PageRoutes"
import { getBalance, getOverrideOptions } from "./actions/references";
import { usePrevious } from "./hooks";
import { useToaster } from "rsuite";


export const RtlContext = React.createContext(false);
export const PermissionContext = React.createContext(null);


const App = ({
    auth,
    lang,
    options,
    getBalance,
    getOverrideOptions
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const toaster = useToaster();

    const locale = lang;
    const messages = locales[locale];
    const localesRsuite = {en, ar};
    const rsMessages = localesRsuite[locale];
    const currentDialerThemeId = 1;
    const rtl = false;
    const permissions = [];

    const prevAuth = usePrevious(auth);

    useEffect(() => {
        getOverrideOptions();
    }, []);

    useEffect(() => {
        if (auth) {
            getBalance();
        }

        if (!auth) {
            navigate(DEFAULT_NOT_AUTH_PATH);
        } else if (prevAuth !== auth) {
            navigate(DEFAULT_AUTH_PATH);
        }
    }, [auth, prevAuth]);

    useLayoutEffect(() => {
        window.toaster = toaster || {};
    }, []);

    return (
        <IntlProvider locale={locale} messages={messages}>
            <RSIntlProvider locale={rsMessages}>
                <ThemeProvider theme={themeContext[currentDialerThemeId]}>
                    <RtlContext.Provider value={rtl}>
                        <PermissionContext.Provider value={permissions}>
                            {/* <Router> */}

                            <PageRoutes auth={auth} options={options}/>
                             
                                    {/* <Route path="/reset-password/:token?" component={ResetPassword}/>
                                    <Route path="/verify-mail/:token?" component={EmailVerification}/>
                                    {!auth && appType !== APP_TYPE_ADMIN &&
                                        <Route exact path="/payment-detail/:token" component={PaymentDetailConfirm}/>
                                    } */}
                                    {/* {!auth &&
                                        <Route path="*" element={<Navigate to={DEFAULT_NOT_AUTH_PATH}/>}/>
                                    } */}

                            {/* </Router> */}

                            {/* <ModalResponseHandlerGlobalHOC /> */}
                            <CacheBooster />
                        </PermissionContext.Provider>
                    </RtlContext.Provider>
                </ThemeProvider>
            </RSIntlProvider>
        </IntlProvider>
    )
}


const mapState = ({auth, references}) => ({
    auth: auth.auth,
    lang: auth.lang,
    options: references.options,
});

export default connect(mapState, {
    getBalance,
    getOverrideOptions
})(App);
