import {Form, InputNumber, SelectPicker} from "rsuite";

// className="custom-field-number-form-control"
export const FieldNumber = (props) => {
    return (
        <FieldInput
            accepter={InputNumber}
            className="custom-field-number-form-control"
            {...props}
        />
    );
};

export const FieldSelect = (props) => {
    return (
        <FieldInput
            accepter={SelectPicker}
            className="custom-field-select-form-control"
            {...props}
        />
    );
};

export const FieldInput = ({name, label, width, ...props}) => {
    return (<Form.Group className="custom-form-group" style={{width}}>
        {label &&
            <div className="custom-label-wrapper">
                <Form.ControlLabel className="custom-control-label">{label}</Form.ControlLabel>
            </div>
        }
        <Form.Control
            className="custom-form-control"
            name={name}
            {...props}
        />
    </Form.Group>)
};