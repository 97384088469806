import React, {useState} from "react";
// import {Button} from "rsuite";
import OverrideTable from "../../components/Override/Table";
import BaseTable from "../../components/base/BaseTable";
import Button from "../../components/Override/Button";
import { withTranslation } from "../../hoc/WithTranslation";
import { withMobile } from "../../hoc/WithMobile";
import { mobileColumnAlign } from "../../utils";


const PaymentsTable = ({
    // api data
    data,
    count,
    page,
    perPage,
    // storage data
    dataLoading,
    // other data
    options,

    getItems,
    exportFunc,

    isMobile,
    isTablet,
    isIssueWidth,

    m,
    formatMessage
}) => {
    const columns = [
        {
            id: "date",
            label: formatMessage(m.date),
            dataKey: "date",
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 150
        },
        {
            id: "amount",
            label: formatMessage(m.amount),
            dataKey: "amount",
            value: ({amount, currency}) => {
                return `${amount} ${currency}`;
            },
            align: mobileColumnAlign(isMobile, "right"),
            flexGrow: 1,
            minWidth: 120
        },
        {
            id: "comment",
            label: formatMessage(m.comment),
            dataKey: "comment",
            align: mobileColumnAlign(isMobile),
            flexGrow: 3,
            minWidth: 300
        }
    ];

    return (
        <OverrideTable
            component={BaseTable}
            columns={columns}
            options={options}
            height={400}
            rowHeight={40}

            data={data}
            count={count}
            page={page}
            perPage={perPage}
            pagination

            loading={dataLoading}
            disabled={dataLoading}

            export={exportFunc}
            getItems={getItems}

            isMobile={isMobile}
            isTablet={isTablet}
            isIssueWidth={isIssueWidth}
        
        />
    );
};

export default withMobile(withTranslation(PaymentsTable), {issueWidth: 1000, tablet: 600, mobile: 500});