import React from "react";
import {Icon as RSIcon} from "@rsuite/icons";

const FaSvgIcon = ({ faIcon, ...rest }) => {
    const { width, height, svgPathData } = faIcon;
    return (
        <svg viewBox={`0 0 ${width} ${height}`} width="2em" height="2em" fill="currentColor" {...rest}>
        <path d={svgPathData}></path>
        </svg>
    );
};

export const Icon = ({icon, ...rest}) => {
    return <RSIcon as={FaSvgIcon} faIcon={icon} {...rest}/>
};