import React from 'react';
import Layout from "../Layout";
import {connect} from "react-redux";
import {Outlet} from "react-router-dom";


const RouteWrapper = ({auth, menuText, ...props}) => {
    return <Layout menuText={menuText}>
        <Outlet/>
    </Layout>
};

const mapState = ({auth})=> ({
    auth: auth.auth,
    lang: auth.lang,
    rtl: auth.rtl,
});

export default connect(mapState)(RouteWrapper);