import React from "react";
// import {Table} from "rsuite";
import OverrideTable from "../../components/Override/Table";
import BaseTable from "../../components/base/BaseTable";
import Button from "../../components/Override/Button";
import {getClearStrDate} from "../../utils/date";
import { withTranslation } from "../../hoc/WithTranslation";
import { withMobile } from "../../hoc/WithMobile";
import { mobileColumnAlign } from "../../utils";


const CDRTable = ({
    data,
    count,
    page,
    perPage,
    dataLoading, 
    options,

    exportFunc,
    getItems,

    isMobile,
    isTablet,
    isIssueWidth,

    m,
    formatMessage
}) => {

    const columns = [
        {
            id: "datetime",
            label: formatMessage(m.datetime),
            dataKey: "datetime",
            value: ({datetime}) => {
                return getClearStrDate(datetime);
            },
            align: mobileColumnAlign(isMobile, "left"),
            flexGrow: 1,
            minWidth: 150
        },
        {
            id: "cli",
            label: formatMessage(m.cli),
            dataKey: "cli",
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 120
        },
        {
            id: "phone",
            label: formatMessage(m.number),
            dataKey: "phone",
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 120
        },
        {
            id: "name",
            label: formatMessage(m.name),
            dataKey: "name",
            align: mobileColumnAlign(isMobile),
            flexGrow: 2,
            minWidth: 280
        },
        {
            id: "duration",
            label: formatMessage(m.duration),
            dataKey: "duration",
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 80
        },
        {
            id: "rate",
            label: formatMessage(m.pricePerMinute),
            dataKey: "rate",
            value: ({rate, currency}) => {
                return `${rate} ${currency}`;
            },
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 100
        },
        {
            id: "cost",
            label: formatMessage(m.cost),
            dataKey: "cost",
            value: ({cost, currency}) => {
                return `${cost} ${currency}`;
            },
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 100
        }
    ];

    return (
        <OverrideTable
            component={BaseTable}
            columns={columns}
            options={options}
            loading={dataLoading}

            height={400}
            rowHeight={40}

            data={data}
            count={count}
            page={page}
            perPage={perPage}
            pagination

            export={exportFunc}

            getItems={getItems}

            isMobile={isMobile}
            isTablet={isTablet}
            isIssueWidth={isIssueWidth}
        />
    );
};

export default withMobile(withTranslation(CDRTable), {issueWidth: 1000, tablet: 767, mobile: 500});