import React, {useState} from "react";
import { Checkbox, DateRangePicker } from "rsuite";
import OverrideForm from "../../components/Override/Form";
import FilterForm from "../../components/base/FilterForm";
import { debounce, parseOptionPosition } from "../../utils";

import BaseCheckboxGroup from "../../components/base/BaseCheckboxGroup";
import { withTranslation } from "../../hoc/WithTranslation";
import { withMobile } from "../../hoc/WithMobile";



const TrafficReportFilter = ({
    value,
    setValue,

    applyFilters,
    
    options,

    isMobile,
    isIssueWidth,

    m,
    formatMessage
}) => {
    const [group, setGroup] = useState(value.group || []);
    
    const formFields = [
        {
            name: "date",
            accepter: DateRangePicker,
            showOneCalendar: isMobile,
            cleanable: false,
            width: 240
        },
        {
            name: "filter_cli",
            placeholder: `${formatMessage(m.aNumber)}...`,
            width: 240
        },
        {
            name: "filter_phone",
            placeholder: `${formatMessage(m.bNumber)}...`,
            width: 240
        }
    ];
    

    return (
        <OverrideForm
            formDefaultValue={value}
            onChange={debounce((formValue) => {
                setValue((value) => {
                    return {
                        ...value,
                        ...formValue
                    }
                });
            }, 200)}
            fields={formFields}
            component={FilterForm}  
            options={options}
            // component props
            position={parseOptionPosition(options)}
            applyFunc={applyFilters}
            applyButton

            isIssueWidth={isIssueWidth}
        >
            <BaseCheckboxGroup
                inline
                name="group"
                title={`${formatMessage(m.groupBy)}:`}
                value={group}
                onChange={(value) => {
                    setGroup(value);
                    setValue((currentValue) => {
                        return {
                            ...currentValue,
                            group: value,
                        }
                    });
                }}
            >
                <Checkbox value="cli">{formatMessage(m.cli)}</Checkbox>
                <Checkbox value="phone">{formatMessage(m.phone)}</Checkbox>
                <Checkbox value="price">{formatMessage(m.price)}</Checkbox>
            </BaseCheckboxGroup>
        </OverrideForm>
    )
};

export default withMobile(withTranslation(TrafficReportFilter), {mobile: 620, issueWidth: 1540});