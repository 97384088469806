import {defineMessages} from "react-intl";

export default defineMessages({
    exportReport: { id: "traffic.exportReport", defaultMessage: "Export Report", description: "traffic: Export Report" },
    dateFrom: {id: "traffic.cdrFilters.dateFrom", defaultMessage: "Date from", description: "Traffic, CDRFilters: Date from"},
    groupBy: { id: "traffic.groupBy", defaultMessage: "Group by", description: "traffic: Group by" },
    trafficStat: { id: "traffic.trafficStat", defaultMessage: "Traffic stat", description: "Traffic: Traffic stat" },
    cdr: { id: "traffic.cdr", defaultMessage: "CDR", description: "Traffic: CDR" },
    mdr: { id: "traffic.mdr", defaultMessage: "MDR", description: "Traffic: MDR" },
    messages: { id: "traffic.messages", defaultMessage: "Messages", description: "Traffic: Messages" },
    message: { id: "traffic.message", defaultMessage: "Message", description: "Traffic, AccessList, CLI: Message" },
    period: { id: "traffic.period", defaultMessage: "Period", description: "Traffic: Period" }
})