import React from "react";
import {Table} from "rsuite";
import styled, {css} from "styled-components";


export default styled(Table).attrs(props => ({
    className: props.className || "override-table"
}))`
    && .rs-table-body-row-wrapper {
        background-color: var(--color-table-bg);
    }
    && .rs-table-cell  {
        background-color: var(--color-table-bg);
        border-color: var(--color-table-borders-inside);
    }
    && .rs-table-cell-header .rs-table-cell {
        background-color: var(--color-table-bg-header);
        color: var(--color-table-header-color);
    }
`;