import React, {useMemo, useState, useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "../../hoc/WithRouterHOC";
import PanelLayout from "../../components/Override/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import Title from "../../components/Override/Title";

import AccessListTable from "./AccessListTable";
import AccessListFilter from "./AccessListFilter";

import {
    getOptionParams,
    getValuesByKeyList,
    getServiceByLocation,
    objectClearEmptyValues
} from "../../utils";

import { getAccessList } from "../../actions/accessList";


const defaultFilterValue = {
    filter_cli: null,
    filter_phone: null
};


const AccessList = ({
    // api data
    dataList,
    count,
    page,
    perPage,
    // api methods
    getList,
    // stored data
    dataLoading,
    // other data
    header,
    options,
    location
}) => {
    Title(header);

    const tableOptions = getOptionParams(options, location, "table");
    const filterOptions = getOptionParams(options, location, "filter");

    const serviceByLocation = getServiceByLocation(location);

    const [filter, setFilter] = useState(defaultFilterValue);

    const getFormattedFilter = (filter) => {
        const clearFilter = objectClearEmptyValues(filter);
        const neededValuesFilter = getValuesByKeyList(clearFilter, ["filter_cli", "filter_phone"]);

        const formattedFilter = {
            ...neededValuesFilter
        };

        return formattedFilter;
    };

    const formattedFilter = useMemo(() => {
        return getFormattedFilter(filter);
    }, [filter]);

    useEffect(() => {
        update();
    }, [filter]);

    const getItems = (_page, _perPage) => {
        getList(formattedFilter, _page, _perPage);
    };

    const onExport = () => {
        update(true);
    };

    const update = (csv=false) => {
        const csvFileName = csv ? "access-list.csv" : null;
        getList(formattedFilter, 1, perPage, csvFileName);
    };


    return <PanelLayout>
        <PageHeader title={header}></PageHeader>
        <AccessListFilter
            value={filter}
            setValue={setFilter}
            options={filterOptions}
            disabled={dataLoading}
        />
        <AccessListTable
            data={dataList}
            count={count}
            page={page}
            perPage={perPage}
            dataLoading={dataLoading}
            options={tableOptions}
            filter={filter}

            exportFunc={onExport}
            getItems={getItems}
        />
    </PanelLayout>
};

const mapState = ({accessList, references}) => ({
    dataList: accessList.list,
    count: accessList.count,
    page: accessList.page,
    perPage: accessList.perPage,
    dataLoading: accessList.loading,
    options: references.options["pages"]
});

const mapMethods = {
    getList: getAccessList
};

export default withRouter(connect(mapState, mapMethods)(AccessList));