export * from "./apiMethods";
export * from "./localStorageKeys";

// PATHS
export const DEFAULT_AUTH_PATH = "/live-calls";// "/hot-accesses";
export const DEFAULT_NOT_AUTH_PATH = "/login";


// ACTION METHODS
// auth
export const SET_AUTH = "auth::SET_AUTH ";
export const SET_SERVICE = "auth::SET_SERVICE";
export const SET_SECOND_AUTH = "auth::SET_SECOND_AUTH";
export const SET_LOADING_INFO = "auth::SET_LOADING_INFO";
export const SET_INFO_ACCOUNT = "auth::SET_INFO_ACCOUNT";
export const SET_USER_LOGIN_INFO = "auth::SET_USER_LOGIN_INFO";
export const SET_MAINTENANCE_MODE = "auth::SET_MAINTENANCE_MODE";
export const SET_USER_LOCALIZATION = "auth::SET_USER_LOCALIZATION";
export const SET_ACCOUNT_DIALER_INFO = "auth::SET_ACCOUNT_DIALER_INFO";
export const SET_UPLOAD_LOADING_DIALER = "auth::SET_UPLOAD_LOADING_DIALER";

// access list
export const ACCESS_LIST_SET_LOADING = "accessList::ACCESS_LIST_SET_LOADING";
export const ACCESS_LIST_SET_LIST = "accessList::ACCESS_LIST_SET_LIST";

// cdr
export const CDR_SET_LOADING = "cdr::CDR_SET_LOADING";
export const CDR_SET_LIST = "cdr::CDR_SET_LIST";

// payments
export const PAYMENTS_SET_LOADING = "payments::PAYMENTS_SET_LOADING";
export const PAYMENTS_SET_LIST = "payments::PAYMENTS_SET_LIST";
export const TRAFFIC_SET_LOADING = "payments::TRAFFIC_SET_LOADING";
export const TRAFFIC_SET_LIST = "payments::TRAFFIC_SET_LIST";
// traffic report
export const TRAFFIC_REPORT_SET_LOADING = "traffic_report::TRAFFIC_REPORT_SET_LOADING";
export const TRAFFIC_REPORT_SET_LIST = "traffic_report::TRAFFIC_REPORT_SET_LIST";
// roles
export const SET_ROLES = "roles::SET_ROLES";
// references
export const BALANCE_SET_LOADING = "references::BALANCE_SET_LOADING";
export const BALANCE_SET_LIST = "references::BALANCE_SET_LIST";
// live calls
export const LIVE_CALLS_SET_LOADING = "live_calls::LIVE_CALLS_SET_LOADING";
export const LIVE_CALLS_SET_LIST = "live_calls::LIVE_CALLS_SET_LIST";

// OVERRIDE
export const OVERRIDE_SET_OPTIONS = "references::OVERRIDE_SET_OPTIONS";

// permission
export const SET_PERMISSION = "permission::SET_PERMISSION";
export const SET_PERMISSION_ADD = "permission::SET_PERMISSION_ADD";

// numbers
export const NUMBERS_SET_LOADING = "numbers::NUMBERS_SET_LOADING";
export const NUMBERS_SET_LIST = "numbers::NUMBERS_SET_LIST";

// MESSAGES
export const DEFAULT_ERROR_MESSAGE = "Something went wrong";


// VALUES
// screen
export const SCREEN_MEDIA = {
    xxs: {min: 0, max: 479.98},
    xs: {min: 480, max: 575.98},
    sm: {min: 576, max: 767.98},
    md: {min: 768, max: 991.98},
    lg: {min: 992, max: 1199.98},
    xl: {min: 1200, max: 1399.98},
};

export const USD_CURRENCY_NAME = 'USD';
export const USD_DEFAULT_CURRENCY_KEY = 1;

export const EUR_CURRENCY_NAME = 'EUR';
export const EUR_DEFAULT_CURRENCY_KEY = 2;

export const currency_data = {
    [USD_CURRENCY_NAME]: USD_DEFAULT_CURRENCY_KEY,
    [EUR_CURRENCY_NAME]: EUR_DEFAULT_CURRENCY_KEY
};

export const currency_names_data = {
    [USD_DEFAULT_CURRENCY_KEY]: USD_CURRENCY_NAME,
    [EUR_DEFAULT_CURRENCY_KEY]: EUR_CURRENCY_NAME
};

export const ScreenPositions = {
	LEFT: 0,
	RIGHT: 1
};

export const parsedScreenPositions = {
    "left": 0,
    "right": 1
};


export const transformed_service_data = {
    "voice": true,
    "sms": false
};

export const transformed_service_reverse_data = {
    [true]: "voice",
    [false]: "sms"
};

export const DEFAULT_PAGINATION_LIMIT = 10;

export const DEFAULT_SP_KEY = 2;
export const SMS_DEFAULT_SP_KEY = 101;
export const DEFAULT_PRICE_KEY = 2;
export const SMS_DEFAULT_PRICE_KEY = 101;

export const MIN_PER_PAGE_SMALL = 10;
export const MIN_PER_PAGE_MEDIUM = 15;
export const MIN_PER_PAGE_LARGE = 30;

export const DEFAULT_TITLE_SET = 1;

// error
export const ERROR_CODES = {
    1: "Authentication error",      // authentication
    2: "No Permission",             // authorization
    3: "Validation error",          // validation
    4: "Data error",                // data
    DEFAULT: "Server error"
};

export const ERROR_CODE_TOO_MUCH_OPERATIONS = -32001;