import React, {useState} from "react";
import {Pagination as TablePagination} from "rsuite";
import OverrideTable from "../../../components/Override/Table";
import BaseTable from "../../../components/base/BaseTable";
import Button from "../../../components/Override/Button";
import { withTranslation } from "../../../hoc/WithTranslation";
import { withMobile } from "../../../hoc/WithMobile";
import { mobileColumnAlign } from "../../../utils";


const TrafficTable = ({
    // api data
    data,
    count,
    page,
    perPage,
    // storage data
    dataLoading,
    // other data
    options,

    getItems,
    exportFunc,
    
    isMobile,
    isTablet,
    isIssueWidth,

    m,
    formatMessage
}) => {
    const columns = [
        {
            id: "service",
            label: formatMessage(m.service),
            dataKey: "service",
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 70
        },
        {
            id: "period",
            label: formatMessage(m.period),
            dataKey: "period_from",
            value: ({period_from, period_to}) => {
                return `${period_from} - ${period_to}`;
            },
            align: mobileColumnAlign(isMobile),
            flexGrow: 3,
            minWidth: 200
        },
        {
            id: "amount",
            label: formatMessage(m.amount),
            dataKey: "amount",
            value: ({amount, currency}) => {
                return `${amount} ${currency}`;
            },
            align: mobileColumnAlign(isMobile, "right"),
            flexGrow: 1,
            minWidth: 120
        }
    ];

    return (<>
        <OverrideTable
            component={BaseTable}
            columns={columns}
            options={options}
            height={400}
            rowHeight={40}
            data={data}
            count={count}
            page={page}
            perPage={perPage}
            pagination
            loading={dataLoading}
            disabled={dataLoading}

            export={exportFunc}
            getItems={getItems}

            isMobile={isMobile}
            isTablet={isTablet}
            isIssueWidth={isIssueWidth}
        />
    </>
    );
};

export default withMobile(withTranslation(TrafficTable), {issueWidth: 1000, tablet: 600, mobile: 500});