import React from "react";
import {connect} from "react-redux";
import {Container, Content, Sidebar} from "rsuite";

import ErrorBoundary from "./Error";

import {pageIdLogObject} from "../utils/pageIdLogger";
import {useResolvedPath, useLocation} from "react-router";
import {useWindowWidth} from "../hooks";

import {ScreenPositions, parsedScreenPositions} from "../const";
import Menu from "./Menu/Menu";

import {withRouter} from "../hoc/WithRouterHOC";
import { logoutUser } from "../actions/auth";
import { getOption } from "../utils";


const Layout = ({
    options,
    balance,

    logoutUser,

    menuText,

    lang,
    rtl,

    ...props
}) => {

    const sidebarOptions = getOption(options, "sidebar");
    const screenPosition = getOption(sidebarOptions, "screenPosition");
    const collapsibleMenu = getOption(sidebarOptions, "collapsible");
    const screenPositionValue = screenPosition ? parsedScreenPositions[screenPosition] : ScreenPositions.LEFT;


    const windowWidth = useWindowWidth();
    const routeMatch = useResolvedPath("");

    const location = useLocation();

    // set logger
    if (routeMatch.path === "/" && routeMatch.path !== location.pathname) {
        routeMatch.path = location.pathname;
        routeMatch.pathname = location.pathname;
    }

    pageIdLogObject.pageMatch = routeMatch;

    const [expand, setExpand] = React.useState(true);

    // set rtl DOM
    // useEffect(() => {
    //     if ( rtl && !["/login", "/reset-password"].includes(routeMatch.path) ) {
    //         document.body.classList.add("rtl");
    //     } else {
    //         document.body.classList.remove("rtl");
    //     }

    // }, [rtl, lang, routeMatch]);

    const menuProps = {
        expand,
        balance,
        setExpand,
        logoutUser,
        menuText,

        collapsible: collapsibleMenu,
        screenPosition: screenPositionValue,
    };


    return (
        <Container style={{minHeight: "100vh"}}>
         
            {screenPositionValue === ScreenPositions.LEFT && <Menu
                {...menuProps}
            />}
            {/* </Header> */}
            <Container>
                <Content>
                    <ErrorBoundary>
                        {props.children}
                    </ErrorBoundary>
                </Content>
            </Container>
            {screenPositionValue === ScreenPositions.RIGHT && <Menu
                {...menuProps}
            />}
            {/* <Footer>Footer</Footer> */}
        </Container>
    );
}

const mapState = ({references}) => ({
    options: references.options,
    balance: references.balance
});

const mapMethods = {
    logoutUser
};

export default withRouter(connect(mapState, mapMethods)(Layout));

// const mapState = ( {auth, references, accounts, roles} )=> ({
//     auth: auth.auth,
//     service: auth.service,
//     lang: auth.lang,
//     rtl: auth.rtl,
//     roles: roles.role_list,
//     method_list: references.method_list,
//     accountInfo: accounts.accountInfo,
//     error: accounts.editError,
//     errorMessage: accounts.editErrorMessage
// });

// export default connect(mapState, {
//     openAllocationModal,
//     logoutUser,
//     modifyAccountRequest,
//     getRegistrationCount,
//     getRealtimePaymentStatus,
// })(Layout);
