import {api} from "../api/loginRoutes";
import {createAction} from "./defaults";

import {
    PAYMENTS_SET_LOADING,
    PAYMENTS_SET_LIST,
    TRAFFIC_SET_LOADING,
    TRAFFIC_SET_LIST,
    MIN_PER_PAGE_SMALL
} from "../const";
import {
    PAYMENTS_GET_LIST_METHOD,
    TRAFFIC_GET_LIST_METHOD
} from "../const/apiMethods";

import {getFileFromApi} from "../utils/apiHelpers";

const setLoading = createAction(PAYMENTS_SET_LOADING);
const setPaymentsList = createAction(PAYMENTS_SET_LIST);
const setTrafficLoading = createAction(TRAFFIC_SET_LOADING);
const setTrafficList = createAction(TRAFFIC_SET_LIST);


export const getPaymentsList = (filter, page=1, perPage=MIN_PER_PAGE_SMALL, exportCsvName=null) => async (dispatch) => {
    dispatch(setLoading(true));

    const method = PAYMENTS_GET_LIST_METHOD;

    const params = {
        ...filter,
        ...(!exportCsvName ? {
            page,
            per_page: perPage
        } : {})
    };

    if (exportCsvName) {
        return await getFileFromApi(method, params, exportCsvName, () => {
            dispatch(setLoading(false));
        });
    }

    await api(method, params).then((response) => {
        if (response.payment_list) {
            dispatch(setPaymentsList({
                list: response.payment_list,
                count: response.payment_count,
                page,
                perPage
            }));
        }
    })
    .finally(() => {
        dispatch(setLoading(false));
    })
};

export const getTrafficList = (filter, page=1, perPage=MIN_PER_PAGE_SMALL, exportCsvName=null) => async (dispatch) => {
    dispatch(setTrafficLoading(true));

    const method = TRAFFIC_GET_LIST_METHOD;

    const params = {
        ...filter,
        ...(!exportCsvName ? {
            page,
            per_page: perPage
        } : {})
    };

    if (exportCsvName) {
        return await getFileFromApi(method, params, exportCsvName, () => {
            dispatch(setTrafficLoading(false));
        });
    }

    await api(method, params).then((response) => {
        if (response.traffic_stat_list) {
            dispatch(setTrafficList({
                list: response.traffic_stat_list,
                count: response.traffic_stat_count,
                page,
                perPage
            }));
        }
    })
    .finally(() => {
        dispatch(setTrafficLoading(false));
    })
};
