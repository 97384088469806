import React, {useState} from "react";
import { Checkbox } from "rsuite";
import styled from "styled-components";

const BaseCheckbox = ({title, name, onCheckFunc=null, defaultChecked=false, ...props}) => {
    const [checked, onCheck] = useState(defaultChecked);

    return (<StyledCheckbox
        name={name}
        checked={checked}
        onChange={(_, _checked) => {
            onCheck(_checked);
            if (onCheckFunc) {
                onCheckFunc(_checked);
            }
        }}
        {...props}
    >
        {title ? title : <></>}
    </StyledCheckbox>)
};

export default BaseCheckbox;

const StyledCheckbox = styled(Checkbox)`
    && {

    }
`;