import React from "react";
// import {Table} from "rsuite";
import OverrideTable from "../../components/Override/Table";
import BaseTable from "../../components/base/BaseTable";
import { withTranslation } from "../../hoc/WithTranslation";

import { mobileColumnAlign } from "../../utils";
import { withMobile } from "../../hoc/WithMobile";


const MyNumbersTable = ({
    data,
    count,
    page,
    perPage,
    options,
    exportFunc,
    getItems,

    isMobile,
    isTablet,
    isIssueWidth,

    m,
    formatMessage
}) => {

    const columns = [
        {
            id: "phone",
            label: formatMessage(m.number),
            dataKey: "phone",
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 120
        },
        {
            id: "name",
            label: formatMessage(m.name),
            dataKey: "name",
            align: mobileColumnAlign(isMobile),
            flexGrow: 2,
            minWidth: 280
        },
        {
            id: "rate",
            label: formatMessage(m.price),
            dataKey: "rate",
            value: ({rate, currency}) => {
                return `${rate} ${currency}`;
            },
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 100
        }
    ];

    return (
        <OverrideTable
            component={BaseTable}
            columns={columns}
            options={options}
            
            height={400}
            rowHeight={40}

            data={data}
            count={count}
            page={page}
            perPage={perPage}
            pagination

            export={exportFunc}
            
            getItems={getItems}

            isMobile={isMobile}
            isTablet={isTablet}
            isIssueWidth={isIssueWidth}
        
        />
    );
};

export default withMobile(withTranslation(MyNumbersTable), {issueWidth: 1000, tablet: 767, mobile: 500});