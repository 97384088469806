import React from "react";
import {getOptionParams, objectHasParams} from "../../utils";
import {useLocation} from "react-router-dom";
import {PositionedContent, StyledPanel} from "../base/BaseLogin";

const LoginPanel = ({
    panelOptions,
    contentOptions,

    children,
    className,
    classNameContent,
    header=<h3>Header</h3>
}) => {

    return <PositionedContent
        options={contentOptions}
        optionsExists={objectHasParams(contentOptions)}
        className={classNameContent}
    >
        <StyledPanel
            header={header}
            className={className}
            options={panelOptions}
            optionsExists={objectHasParams(panelOptions)}
        >
            {children}
        </StyledPanel>
    </PositionedContent>
};

export default LoginPanel;