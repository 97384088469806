import { 
    PAYMENTS_SET_LIST,
    PAYMENTS_SET_LOADING,
    TRAFFIC_SET_LIST,
    TRAFFIC_SET_LOADING,
    MIN_PER_PAGE_SMALL
} from "../const";


const initialState = {
    list: [],
    trafficCount: 0,
    trafficPage: 1,
    trafficPerPage: MIN_PER_PAGE_SMALL,
    paymentsCount: 0,
    paymentsPage: 1,
    paymentsPerPage: MIN_PER_PAGE_SMALL,
    trafficList: [],
    loading: false,
    trafficLoading: false
};

const handlers = {
    [PAYMENTS_SET_LIST]: (state, {payload: {list, count, page, perPage}}) => ({
        ...state, 
        list,
        paymentsCount: count,
        paymentsPage: page,
        paymentsPerPage: perPage
    }),
    [PAYMENTS_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [TRAFFIC_SET_LIST]: (state, {payload: {list, count, page, perPage}}) => ({
        ...state, 
        trafficList: list,
        trafficCount: count,
        trafficPage: page,
        trafficPerPage: perPage
    }),
    [TRAFFIC_SET_LOADING]: (state, {payload}) => ({...state, trafficLoading: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};



