import {api, getFileResponse} from "../api/loginRoutes";
import {createAction} from "./defaults";

import {
    MIN_PER_PAGE_SMALL,
    TRAFFIC_REPORT_SET_LIST,
    TRAFFIC_REPORT_SET_LOADING
} from "../const";
import {
    TRAFFIC_REPORT_GET_LIST_METHOD
} from "../const/apiMethods";
import {getFileFromApi} from "../utils/apiHelpers";

const setTrafficReportLoading = createAction(TRAFFIC_REPORT_SET_LOADING);
const setTrafficReportList = createAction(TRAFFIC_REPORT_SET_LIST);


export const getTrafficReportList = (filter, page=1, perPage=MIN_PER_PAGE_SMALL, exportCsvName=null) => async (dispatch) => {
    dispatch(setTrafficReportLoading(true));

    const method = TRAFFIC_REPORT_GET_LIST_METHOD;

    const params = {
        ...filter,
        ...(!exportCsvName ? {
            page,
            per_page: perPage
        } : {})
    };

    if (exportCsvName) {
        return await getFileFromApi(method, params, exportCsvName, () => {
            dispatch(setTrafficReportLoading(false));
        });
    }


    await api(method, params).then((response) => {
        if (response.cdr_report_list) {
            dispatch(setTrafficReportList({
                list: response.cdr_report_list,
                count: response.cdr_report_count,
                page,
                perPage
            }));
        }
    })
    .finally(() => {
        dispatch(setTrafficReportLoading(false));
    })
};
