import React from "react";
import OverrideTable from "../../components/Override/Table";
import BaseTable from "../../components/base/BaseTable";
import Button from "../../components/Override/Button";

import {Whisper, Tooltip, Popover} from "rsuite";

import { withTranslation } from "../../hoc/WithTranslation";
import { withMobile } from "../../hoc/WithMobile";


const AccessListTable = ({
    data,
    count,
    page,
    perPage,
    dataLoading, 
    options,

    exportFunc,
    getItems,

    isMobile,
    isTablet,
    isIssueWidth,

    m,
    formatMessage
}) => {
    const columns = [
        {
            id: "origin",
            label: formatMessage(m.origin),
            dataKey: "origin",
            align: isMobile ? "left" : "center",
            value: ({origin}) => {
                return isMobile ? <Whisper
                    speaker={<Tooltip>{origin}</Tooltip>}
                    trigger="hover"
                    placement="auto"
                >
                    <span>{origin}</span>
                </Whisper> : origin;
            },
            flexGrow: 1,
            minWidth: 180
        },
        {
            id: "destination",
            label: formatMessage(m.destination),
            dataKey: "destination",
            align: isMobile ? "left" : "center",
            value: ({destination}) => {
                return isMobile ? <Whisper
                    speaker={<Tooltip>{destination}</Tooltip>}
                    trigger="hover"
                    placement="auto"
                >
                    <span>{destination}</span>
                </Whisper> : destination;
            },
            flexGrow: 1,
            minWidth: 180
        },
        {
            id: "rate",
            label: formatMessage(m.rate),
            dataKey: "rate",
            value: ({rate, currency}) => {
                return `${rate} ${currency}`;
            },
            align: isMobile ? "right" : "center",
            flexGrow: 1,
            minWidth: 80
        }
    ];

    return (
        <OverrideTable
            component={BaseTable}
            columns={columns}
            options={options}
            loading={dataLoading}

            height={440}
            rowHeight={40}

            data={data}
            count={count}
            page={page}
            perPage={perPage}
            pagination

            export={exportFunc}

            getItems={getItems}

            isMobile={isMobile}
            isTablet={isTablet}
            isIssueWidth={isIssueWidth}
        />
    );
};

export default withMobile(withTranslation(AccessListTable), {issueWidth: 1000, tablet: 767, mobile: 500});