import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "../../hoc/WithRouterHOC";
import PanelLayout from "../../components/Override/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import Title from "../../components/Override/Title";

import CDRTable from "./CDRTable";
import CDRFilter from "./CDRFilter";

import {
    getOptionParams,
    getValuesByKeyList,
    getServiceByLocation,
    objectClearEmptyValues
} from "../../utils";

import { getCDRList } from "../../actions/cdr";

import {toStringDate, getFormattedDate, toStartDayTime, toEndDayTime} from "../../utils/date";

const defaultFilterValue = {
    date: [toStartDayTime(new Date()), toEndDayTime(new Date())]
};


const getFormattedFilter = (filter) => {
    const clearFilter = objectClearEmptyValues(filter);
    const neededValuesFilter = getValuesByKeyList(clearFilter, ["filter_cli", "filter_phone"]);

    const formattedFilter = {
        ...neededValuesFilter,
        date_start: getFormattedDate(clearFilter.date[0], "-"),
        date_end: getFormattedDate(clearFilter.date[1], "-")
    };

    return formattedFilter;
};

const CDR = ({
    // api data
    dataList,
    count,
    page,
    perPage,
    // api methods
    getList,
    // stored data
    dataLoading,
    // other data
    header,
    options,
    location
}) => {
    Title(header);

    const tableOptions = getOptionParams(options, location, "table");
    const filterOptions = getOptionParams(options, location, "filter");

    const serviceByLocation = getServiceByLocation(location);

    const [filter, setFilter] = useState(defaultFilterValue);
    const [requestFilter, setRequestFilter] = useState(getFormattedFilter(filter));

    const applyFilters = () => {
        const formattedFilter = getFormattedFilter(filter);
        setRequestFilter(formattedFilter);

        getList(formattedFilter, 1, perPage);
    };
    
    useEffect(() => {
        update();
    }, []);


    const getItems = (_page, _perPage) => {
        getList(requestFilter, _page, _perPage);
    };

    const onExport = () => {
        getList(requestFilter, 1, 0, "cdr-list.csv");
    };

    const update = () => {
        getList(requestFilter, 1, perPage);
    };

    return <PanelLayout>
        <PageHeader title={header}></PageHeader>
        <CDRFilter
            value={filter}
            setValue={setFilter}
            options={filterOptions}
            disabled={dataLoading}
            applyFilters={applyFilters}
        />
        <CDRTable
            data={dataList}
            count={count}
            page={page}
            perPage={perPage}
            dataLoading={dataLoading}
            options={tableOptions}
            exportFunc={onExport}
            getItems={getItems}
        />
    </PanelLayout>
};

const mapState = ({cdr, references}) => ({
    dataList: cdr.list,
    count: cdr.count,
    page: cdr.page,
    perPage: cdr.perPage,
    dataLoading: cdr.loading,
    options: references.options["pages"]
});

const mapMethods = {
    getList: getCDRList
};

export default withRouter(connect(mapState, mapMethods)(CDR));