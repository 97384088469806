import React from "react";
// import {Table} from "rsuite";
import OverrideTable from "../../components/Override/Table";
import BaseTable from "../../components/base/BaseTable";
import { withTranslation } from "../../hoc/WithTranslation";
import { withMobile } from "../../hoc/WithMobile";
import { mobileColumnAlign } from "../../utils";


const LiveCallsTable = ({
    data,
    options,

    isMobile,

    m,
    formatMessage
}) => {

    const columns = [
        {
            id: "phone",
            label: formatMessage(m.callingNumber),
            dataKey: "phone",
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 120
        },
        {
            id: "cli",
            label: formatMessage(m.calledNumber),
            dataKey: "cli",
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 120
        },
        {
            id: "name",
            label: formatMessage(m.name),
            dataKey: "name",
            align: mobileColumnAlign(),
            flexGrow: 2,
            minWidth: 280
        },
        {
            id: "rate",
            label: formatMessage(m.price),
            dataKey: "rate",
            value: ({rate, cur_name}) => {
                return `${rate} ${cur_name}`;
            },
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 100
        },
        {
            id: "duration",
            label: formatMessage(m.duration),
            dataKey: "duration",
            align: mobileColumnAlign(isMobile),
            flexGrow: 1,
            minWidth: 80
        }
    ];

    return (
        <OverrideTable
            component={BaseTable}
            columns={columns}
            options={options}
            height={400}
            rowHeight={40}
            data={data}
        />
    );
};

export default withMobile(withTranslation(LiveCallsTable));