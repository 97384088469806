import React, {useState} from "react";
import { Checkbox, DateRangePicker } from "rsuite";
import OverrideForm from "../../components/Override/Form";
import FilterForm from "../../components/base/FilterForm";
import { debounce, parseOptionPosition } from "../../utils";
import { withTranslation } from "../../hoc/WithTranslation";
import { withMobile } from "../../hoc/WithMobile";


const AccessListFilter = ({
    value,
    setValue,

    options,

    isIssueWidth,

    m,
    formatMessage
}) => {

    
    const formFields = [
        {
            name: "filter_cli",
            placeholder: formatMessage(m.originNameOrNumberMinDigits),
            width: 300
        },
        {
            name: "filter_phone",
            placeholder: formatMessage(m.destinationNameOrNumberMinDigits),
            width: 300
        }
    ];

    return (
        <OverrideForm
            formDefaultValue={value}
            onChange={debounce((formValue) => {
                setValue((value) => {
                    return {
                        ...value,
                        ...formValue
                    }
                });
            }, 200)}
            fields={formFields}
            component={FilterForm}  
            options={options}
            // component props
            position={parseOptionPosition(options)}

            isIssueWidth={isIssueWidth}
        >
        </OverrideForm>
    )
};

export default withMobile(withTranslation(AccessListFilter), {issueWidth: 1000});