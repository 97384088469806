import {transformed_service_data} from "../const";
import {Table} from "rsuite";

import _each from "lodash/each";
import _isNil from "lodash/isNil";
import _isEmpty from "lodash/isEmpty";
import _isError from "lodash/isError";
import _isObject from "lodash/isObject";
// import _isString from "lodash/isString";
import _isFunction from "lodash/isFunction";
import _difference from "lodash/difference";
import _filter from "lodash/filter";
import _debounce from "lodash/debounce";
import {ScreenPositions, parsedScreenPositions} from "../const";

const {Column, HeaderCell, Cell} = Table;


// Checks if value is classified as a Function object.
export const isFunction = (f) => _isFunction(f);

// Checks if value is an Error, EvalError, RangeError, ReferenceError, SyntaxError, TypeError, or URIError object.
export const isError = (error) => _isError(error);

// Checks if value is null or undefined.
export const isNil = (value) => _isNil(value);

// Checks if value is an empty object, collection, map, or set.
export const isEmpty = (value) => _isEmpty(value);

// Checks if value is the language type of Object. (e.g. arrays, functions, objects, regexes, new Number(0), and new String(''))
export const isObject = (value) => _isObject(value);

// Creates an array of array values not included in the other given arrays using SameValueZero for equality comparisons. The order and references of result values are determined by the first array.
export const difference = _difference;

// Iterates over elements of collection, returning an array of all elements predicate returns truthy for. The predicate is invoked with three arguments: (value, index|key, collection).
export const filter = _filter;

// Creates a debounced function that delays invoking func until after wait milliseconds have elapsed since the last time the debounced function was invoked. The debounced function comes with a cancel method to cancel delayed func invocations and a flush method to immediately invoke them. Provide options to indicate whether func should be invoked on the leading and/or trailing edge of the wait timeout. The func is invoked with the last arguments provided to the debounced function. Subsequent calls to the debounced function return the result of the last func invocation.
export const debounce = _debounce;

// Iterates over elements of collection and invokes iteratee for each element. The iteratee is invoked with three arguments: (value, index|key, collection). Iteratee functions may exit iteration early by explicitly returning false.
export const each = _each;


export const pipe = (data, filters, model) => {
    if (!Object.keys(filters).length) {
        return data;
    }

    return data.filter(x => {
        for (let f of Object.keys(filters)) {
            if (Object.keys(filters).includes(f) && (!isBoolean(filters[f]) ? !filters[f] : false)) continue;
            if (!model[f](x, filters[f], filters)) return false;
        }
        return true;
    });
};

export const isBoolean = (value) => {
    return typeof value == "boolean";
};


const createDownloadUrl = (data, type = "") => {
    return window.URL.createObjectURL(new Blob([data], {type: type}));
};


export const downloadFileByUrl = (url, name) => {
    if (!url) {
        return;
    }

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 0);
};


export const downloadDataAsFile = (data, name = "file.txt", type = "") => {
    if (data === undefined) {
        return;
    }

    downloadFileByUrl(createDownloadUrl(data, type), name);
};


export const verGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};


export function customDebounce(fn, ms) {
    let timer;
    return _ => {
        clearTimeout(timer);
        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms)
    };
};

// This should be inside the function that is decorator to.
// "metaData" is the last parameter
// if (typeof(metaData) === "function") {
//     metaData.method = method;
//     metaData.callback = () => {
//         setTrafficReportLoading(false);
//     };
//     metaData.getFile = getFileResponse;
//     metaData.downloadFile = downloadDataAsFile;
// }

// This decorator is for functions that support download csv
// export const csvDecorator = (func) => {
//     return (...args) => {
//         const [filter, page, perPage, csvFileName] = args;
//         const metaFunc = func(filter, page, perPage, func);

//         if (csvFileName) {
//             const params = {
//                 filter
//             };

//             metaFunc.getFile(metaFunc.method, params, "csv")
//                 .then((response) => {
//                     metaFunc.downloadFile(response, csvFileName);
//                 })
//                 .finally(() => {
//                     if (metaFunc.callback) {
//                         metaFunc.callback();
//                     }
//                 })

//             return;
//         } else {
//             const dataList = func(filter, page, perPage, func);
//             return dataList;
//         }
//     }
// }


export const clearLocalStorage = () => {
    const keysToSave = ['sessionId', 'rtl', 'lang'];
    const rulePerPage = new RegExp("PerPage$")

    for (const key in localStorage) {
        if (localStorage.hasOwnProperty(key) && !keysToSave.includes(key) && !rulePerPage.test(key)) {
            delete localStorage[key];
        }
    }
};

export const getManagedServices = (authInfo) => {
    const accountSession = authInfo?.session;
    const accountUser = accountSession && accountSession?.account_user;
    const managedServiceList = accountUser?.managed_service_list || [];

    return managedServiceList;
};

export const pickObjectExist = (obj1, obj2) => {
    return Object.keys(obj1) ? obj1 : obj2;
};

export const servicePick = (service, str1, str2) => {
    return service ? str1 : str2;
};

export const servicePickWithNull = (service, str1, str2) => {
    if (service === null) {
        return str1;
    }

    return service ? str1 : str2;
};

export const getServiceSmsPrefix = (service, keyword) => {
    return service ? keyword : `sms.${keyword}`;
};

export const renderColumn = ({label, dataKey, value = null, headerStyle = null, width = 200, id, ...props}) => {
    return (<Column key={id} width={width} {...props}>
        <HeaderCell style={headerStyle}>{label}</HeaderCell>
        <Cell dataKey={dataKey}>{value}</Cell>
    </Column>);
};


// Paths
export const getSpecificValueFromSearch = (location, name) => {
    if (!location || !name) {
        return null;
    }

    const searchParams = new URLSearchParams(location.search);
    return searchParams ? searchParams.get(name) : null;
};

export const getSearchParams = (location) => {
    if (!location) {
        return;
    }

    return new URLSearchParams(location.search);
};

export const getServiceFromLocation = (location) => {
    if (!location) {
        return;
    }
    const searchParams = new URLSearchParams(location.search);
    return searchParams ? transformed_service_data[searchParams.get('service')] : null;
};

export const getServiceByLocation = (location) => {
    if (!location && !(location?.pathname)) {
        return
    }

    const pathPartList = location.pathname.split("-");
    const lastPathPart = pathPartList.length ? pathPartList[pathPartList.length-1] : null;
    const pathService = lastPathPart && ["sms", "voice"].includes(lastPathPart) ? lastPathPart : null;

    const transformedService = {
        "voice": true,
        "sms": false
    };
    
    return pathService ? transformedService[pathService] : null;
    // return false
};

export const getOptionParams = (options, location, destination="", additionalPath=null, defaultReturnValue={}) => {
    if (options && Object.keys(options).length && location) {
        const additionalPathPart = `${additionalPath ? "-" : ""}${additionalPath ? additionalPath : ""}`;
        const optionType = `${location.pathname.replace("/", "")}${additionalPathPart}`;

        const optionsTypes = Object.keys(options);
        let optionParams = options;

        if (optionsTypes.includes(optionType) && !!options[optionType] && destination) {
            optionParams = options[optionType][destination];
        }

        return optionParams;
    }

    return defaultReturnValue;
};

export const getOption = (options, name, defaultReturnValue={}) => {
    return Object.keys(options).length && name in options ? options[name] : defaultReturnValue;
};

export const parseOptionPosition = (options) => {
    return "position" in options ? parsedScreenPositions[options["position"]] : ScreenPositions.LEFT;
};

export const parseFormRefRoot = (ref, fieldList = []) => {
    if (ref && ref.current && ref.current.root && Array.from(ref.current.root.elements).length) {
        const dataList = Array.from(ref.current.root.elements);

        return dataList
            .filter(element => element.name && fieldList.includes(element.name))
            .reduce((result, element) => {
                result[element.name] = element.defaultValue;
                return result;
            }, {})
    }

    return [];
};


export const appendGetParameters = (url, parameters) => {
    // Перевірка, чи об'єкт параметрів не порожній
    if (Object.keys(parameters).length === 0) {
        return url; // Якщо порожній, просто повертаємо початковий URL
    }

    // Створюємо масив рядків для параметрів
    let queryParameters = [];

    // Перебираємо всі ключі об'єкта параметрів
    for (let key in parameters) {
        if (parameters.hasOwnProperty(key)) {
            // Додаємо ключ та його значення до масиву рядків параметрів
            queryParameters.push(encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key]));
        }
    }

    // Об'єднуємо масив рядків параметрів у одну рядок через '&'
    let queryString = queryParameters.join('&');

    // Перевіряємо, чи початковий URL має вже параметри
    let separator = (url.indexOf('?') === -1) ? '?' : '&';

    // Об'єднуємо початковий URL з рядком GET-параметрів
    let finalUrl = url + separator + queryString;

    return finalUrl;
};

export const safetyDeepParamGetter = (params, levels=[]) => {
    const allParamsExists = levels.reduce((path, level, idx) => {
        if (path[idx] && level in path[idx]) {
            path.push(path[idx][level]);
        } 
        return path
    }, [params]);

    return allParamsExists.length === levels.length + 1;
};

export const objectHasParams = (params) => {
    return params && Object.keys(params).length;
};

export const objectClearEmptyValues = (obj) => {
    const filteredKeyList = Object.keys(obj).filter(key => !!obj[key]);
    return {
        ...getValuesByKeyList(obj, filteredKeyList)
    }
};

export const getValuesByKeyList = (obj, keyList=[]) => {
    return keyList.reduce((result, key) => {
        if (obj[key]) {
            result[key] = obj[key];
        }
        return result;
    }, {});
};

export const getGroupValuesByPairs = (value=[], pairs={}) => {
    const pairsKeyList = Object.keys(pairs);
    return pairsKeyList.reduce((result, key) => {
        result[key] = value.includes(pairs[key]);
        return result;
    }, {});
};

export const getStringGroup = (value=[]) => {
    return value.join(" ");
};

export const isPlainObject = (input) => {
    return input && !Array.isArray(input) && typeof input === "object";
 }
 
export const mergeDeep = (target, source, key) => {
     if (typeof target !== "object" || typeof source !== "object") {
         return source;
     }
     
     let merged = source;
     
     if (isPlainObject(source)) {
         merged = { ...source };
     }
     
     for (const key in target) {
         if (!merged.hasOwnProperty(key)) {
             merged[key] = target[key];
         } else {
             merged[key] = mergeDeep(target[key], source[key], key);
         }
     }
     
     return merged;
 };

 export const mobileColumnAlign = (isMobile=false, custom="center") => {
    return isMobile ? "left" : custom;
};