import { 
    LIVE_CALLS_SET_LIST,
    LIVE_CALLS_SET_LOADING
} from "../const";


const initialState = {
    list: [],
    loading: false
};

const handlers = {
    [LIVE_CALLS_SET_LIST]: (state, {payload: {list}}) => ({...state, list}),
    [LIVE_CALLS_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    DEFAULT: state => state
};

export default  (state = initialState, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state,action)
};



