import React, {useState} from "rsuite";
import OverrideForm from "../../components/Override/Form";
import FilterForm from "../../components/base/FilterForm";
import BaseCheckbox from "../../components/base/BaseCheckbox";
import { debounce } from "../../utils";
import { withTranslation } from "../../hoc/WithTranslation";

const LiveCallsFilter = ({
    value,
    setValue,

    options,

    m,
    formatMessage
}) => {

    const formFields = [
        {
            name: "number",
            placeholder: `${formatMessage(m.number)}...`,
            width: 180
        }
    ];
    

    const onCheck = (check) => {
        setValue((currentValue) => {
            return {
                ...currentValue,
                show_test_calls: check
            }
        });
    };

    return (
        <OverrideForm
            formDefaultValue={value}
            onChange={debounce((formValue) => {
                setValue((value) => {
                    return {
                        ...value,
                        ...formValue
                    }
                });
            }, 200)}
            fields={formFields}
            component={FilterForm}
            options={options}
        />
    )
};

export default withTranslation(LiveCallsFilter);