import {api} from "../api/loginRoutes";
import {createAction} from "./defaults";

import {
    BALANCE_SET_LOADING,
    BALANCE_SET_LIST,
    OVERRIDE_SET_OPTIONS
} from "../const";
import {
    BALANCE_METHOD
} from "../const/apiMethods";

// import options from `${process.env.PUBLIC_URL}/template/options`;
import defaultOptions from "../style/defaultOptions.json";

const setLoading = createAction(BALANCE_SET_LOADING);
const setBalance = createAction(BALANCE_SET_LIST);
const setOverrideOptions = createAction(OVERRIDE_SET_OPTIONS);

export const getBalance = () => (dispatch) => {
    dispatch(setLoading(true));
    api(BALANCE_METHOD).then((response) => {
        dispatch(setBalance(response));
        dispatch(setLoading(false));
    })
};

export const getOverrideOptions = () => async (dispatch) => {
    const optionsPath = `${process.env.PUBLIC_URL}/template/options.json`;

    await fetch(optionsPath)
        .then((response) => {
            dispatch(setOverrideOptions(defaultOptions));

            if (response.ok) {
                return response.json();
            }

            return null;
        })
        .then((data) => {
            if (data) {
                dispatch(setOverrideOptions(data));
            }
        })
        .catch((error) => {

        });

};