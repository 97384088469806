import {api, getFileResponse} from "../api/loginRoutes";
import {createAction} from "./defaults";

import {
    MIN_PER_PAGE_SMALL,
    CDR_SET_LIST,
    CDR_SET_LOADING
} from "../const";
import {
    CDR_GET_LIST_METHOD
} from "../const/apiMethods";
import {getFileFromApi} from "../utils/apiHelpers"

const setCDRLoading = createAction(CDR_SET_LOADING);
const setCDRList = createAction(CDR_SET_LIST);


export const getCDRList = (filter, page=1, perPage=MIN_PER_PAGE_SMALL, exportCsvName=null) => async (dispatch) => {
    dispatch(setCDRLoading(true));

    const method = CDR_GET_LIST_METHOD;

    const params = {
        ...filter,
        ...(!exportCsvName ? {
            page,
            per_page: perPage
        } : {})
    };

    if (exportCsvName) {
        return await getFileFromApi(method, params, exportCsvName, () => {
            dispatch(setCDRLoading(false));
        });
    }

    await api(method, params).then((response) => {
        if (response.call_list) {
            dispatch(setCDRList({
                list: response.call_list,
                count: response.call_count,
                page,
                perPage
            }));
        }
    })
    .finally(() => {
        dispatch(setCDRLoading(false));
    })
};
