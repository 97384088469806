import React, {useMemo} from "react";
import {Pagination} from "rsuite";
import {renderColumn} from "../../utils/helpers";
import {MIN_PER_PAGE_SMALL} from "../../const";
import TableFooter from "../base/TableFooter";
import Button from "./Button";
import { FluidSectionFlexboxGrid, StyledItemAutoMargin, StyledItemFitWidth } from "../base/FitContainers";
import { withTranslation } from "../../hoc/WithTranslation";
// import styled, {css} from "styled-components";


const paginationDefaultOptions = {
    prev: false,
    next: false,
    first: true,
    last: true,
    ellipsis: false,
    boundaryLinks: false,
    maxButtons: 5,
    size: "xs",
    layout: ["pager", "|", "limit", "-", "total"],
    limitLessLayout: ["pager", "-", "total"],
    limitOptions: [10, 15, 30]

    // ["total", "-", "limit", "|", "pager", "skip"]
};

const OverrideTable = ({
    columns,
    component: Component,
    options,
    loading=false,
    count=0,
    page=1,
    perPage=MIN_PER_PAGE_SMALL,
    pagination=null,
    clientPagination=null,
    footerButtons=null,
    export: exportFunc=null,
    overrideClassName,

    getItems,

    isMobile,
    isTablet,
    isIssueWidth,

    m,
    formatMessage,
    ...props
}) => {
    const sortColumns = (columns, order) => {
        const columnKeyList = columns.map(field => field.dataKey);
        const orderKeyList = order.filter(key => columnKeyList.includes(key));

        return columns.sort((a, b) => {
            return orderKeyList.indexOf(a.dataKey) - orderKeyList.indexOf(b.dataKey);
        });
    };

    const isAdaptive = isMobile || isTablet || isIssueWidth;

    const filteredColumns = options && "hide" in options ? columns.filter((column) => !options.hide.includes(column.dataKey)) : columns;
    const sortedFields = options && "order" in options ? sortColumns(filteredColumns, options.order) : filteredColumns;
    const overridePaginationOptions = options && "pagination" in options ? options.pagination : {};
    const paginationOptions = useMemo(() => {

        const adaptivePaginationOptions = isAdaptive ? {
            maxButtons: 3
        } : {};

        return {
            ...paginationDefaultOptions, 
            ...overridePaginationOptions,
            ...adaptivePaginationOptions
        };
    }, [options, isAdaptive]);

    const renderedColumns = sortedFields.map(renderColumn);

    const defaultPerPage = !paginationOptions.limitOptions.includes(MIN_PER_PAGE_SMALL) ? paginationOptions.limitOptions[0] : MIN_PER_PAGE_SMALL;

    const [clientPerPage, setClientPerPage] = React.useState(defaultPerPage);
    const [clientPage, setClientPage] = React.useState(1);

    const onChangeClientPerPage = (_perPage) => {
        setClientPage(1);
        setClientPerPage(_perPage);
    };

    const onChangePage = (_page) => {
        getItems(_page, perPage);
    };

    const onChangePerPage = (_perPage) => {
        getItems(1, _perPage);
    };
    
    const tableData = props?.data ? props.data : [];
    const data = clientPagination ? tableData.filter((_, i) => {
        const start = clientPerPage * (clientPage - 1);
        const end = start + clientPerPage;
        return i >= start && i < end;
    }) : tableData;

    const hasPagination = !!(pagination || clientPagination);

    const layout = useMemo(() => {
        const limitLessLayout = paginationDefaultOptions.limitLessLayout;

        return data.length ? paginationDefaultOptions.layout : limitLessLayout;
    }, [data]);

    const footerButtonsContainer = <div className="footer-buttons">
        {footerButtons}
        {exportFunc && <Button
            className="export-button"
            buttonStyle="default"
            disabled={loading}
            onClick={() => {
                exportFunc()
            }}
        >
            {formatMessage(m.exportToCsv)}
        </Button>}
    </div>;

    return <>
        <Component className={overrideClassName}
            {...{
                ...props,
                pagination,
                data: props.loading ? [] : data,

                isMobile,
                isTablet,
                isIssueWidth,
                isAdaptive
            }}
        >
            {renderedColumns}
        </Component>

        <TableFooter 
            {...{
                pagination: hasPagination,

                isMobile,
                isTablet,
                isIssueWidth,
                isAdaptive
            }}
        >
            <FluidSectionFlexboxGrid>
                <StyledItemFitWidth marginRight={10}>
                    {clientPagination ? <Pagination
                        prev={paginationOptions.prev}
                        next={paginationOptions.next}
                        first={paginationOptions.first}
                        last={paginationOptions.last}
                        ellipsis={paginationOptions.ellipsis}
                        boundaryLinks={paginationOptions.boundaryLinks}
                        maxButtons={paginationOptions.maxButtons}
                        size={paginationOptions.size}
                        layout={layout}
                        total={tableData.length}
                        limitOptions={layout.limitOptions}

                        activePage={clientPage}
                        limit={clientPerPage}

                        onChangePage={setClientPage}
                        onChangeLimit={onChangeClientPerPage}
                    /> : 
                    pagination ? <Pagination
                        prev={paginationOptions.prev}
                        next={paginationOptions.next}
                        first={paginationOptions.first}
                        last={paginationOptions.last}
                        ellipsis={paginationOptions.ellipsis}
                        boundaryLinks={paginationOptions.boundaryLinks}
                        maxButtons={paginationOptions.maxButtons}
                        size={paginationOptions.size}
                        layout={layout}
                        total={count}
                        limit={perPage}

                        limitOptions={paginationOptions.limitOptions}
        
                        activePage={page}
              
                        onChangePage={onChangePage}
                        onChangeLimit={onChangePerPage}
                    /> : <></>}
                </StyledItemFitWidth>
                <StyledItemAutoMargin>
                    {footerButtonsContainer}
                </StyledItemAutoMargin>
            </FluidSectionFlexboxGrid>
        </TableFooter>
    </>
};


export default withTranslation(OverrideTable);