import React, {useMemo, useState, useEffect} from "react";
import {connect} from "react-redux";
import PanelLayout from "../../components/Override/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import Title from "../../components/Override/Title";

import PaymentsFilter from "./PaymentsFilter";
import PaymentsTable from "./PaymentsTable";

import {withRouter} from "../../hoc/WithRouterHOC";
import {getOptionParams, getValuesByKeyList, objectClearEmptyValues} from "../../utils";
import {getFormattedDate, addMonthDate, toStartDayTime, toEndDayTime} from "../../utils/date";

import {getTrafficList, getPaymentsList} from "../../actions/payments";
import TrafficFilter from "./Traffic/TrafficFilter";
import TrafficTable from "./Traffic/TrafficTable";

import { Spacer } from "../../components/Spacer";
import { withTranslation } from "../../hoc/WithTranslation";



const defaultFilterValue = {
    date: [toStartDayTime(addMonthDate(new Date(), -3)), toEndDayTime(new Date())]
};

const Payments = ({
    // api data
    dataTrafficList,

    trafficCount,
    trafficPage,
    trafficPerPage,

    dataPaymentList,

    paymentsCount,
    paymentsPage,
    paymentsPerPage,

    // api methods
    getTrafficList,
    getPaymentsList,
    // storage data
    dataTrafficLoading,
    dataPaymentLoading,
    // other data
    header,
    options,
    location,

    m,
    formatMessage
}) => {
    Title(header);

    const filterTrafficOptions = getOptionParams(options, location, "filter", "traffic");
    const filterPaymentsOptions = getOptionParams(options, location, "filter");
    const tableTrafficOptions = getOptionParams(options, location, "table", "traffic");
    const tablePaymentsOptions = getOptionParams(options, location, "table");

    const [trafficFilter, setTrafficFilter] = useState(defaultFilterValue);
    const [paymentFilter, setPaymentFilter] = useState(defaultFilterValue);

    const getFormattedFilter = (filter) => {
        const clearFilter = objectClearEmptyValues(filter);
        const neededValuesFilter = getValuesByKeyList(clearFilter, ["filter_cli", "filter_phone"]);

        const formattedFilter = {
            ...neededValuesFilter,
            date_start: getFormattedDate(clearFilter.date[0], "-"),
            date_end: getFormattedDate(clearFilter.date[1], "-")
        };

        return formattedFilter;
    };

    const formattedTrafficFilter = useMemo(() => {
        return getFormattedFilter(trafficFilter);
    }, [trafficFilter]);

    const formattedPaymentFilter = useMemo(() => {
        return getFormattedFilter(paymentFilter);
    }, [paymentFilter]);

    useEffect(() => {
        updateTraffic();
    }, [trafficFilter]);

    useEffect(() => {
        updatePayments();
    }, [paymentFilter]);

    const onExportTraffic = () => {
        updateTraffic(true);
    };

    const onExportPayments = () => {
        updatePayments(true);
    };

    const updateTraffic = (csv=false) => {
        const csvFileName = csv ? "traffic-list.csv" : null;
        getTrafficList(formattedTrafficFilter, 1, trafficPerPage, csvFileName);
    };

    const updatePayments = (csv=false) => {
        const csvFileName = csv ? "payments-list.csv" : null;
        getPaymentsList(formattedPaymentFilter, 1, paymentsPerPage, csvFileName);
    };

    const getItemsTraffic = (_page, _perPage) => {
        getTrafficList(formattedTrafficFilter, _page, _perPage);
    };

    const getItemsPayments = (_page, _perPage) => {
        getPaymentsList(formattedPaymentFilter, _page, _perPage);
    };


    return <PanelLayout>
        <PageHeader title={header}/>
        <PaymentsFilter
            value={paymentFilter}
            setValue={setPaymentFilter}
            options={filterPaymentsOptions}
        />
        <PaymentsTable
            data={dataPaymentList}
            options={tablePaymentsOptions}
            count={paymentsCount}
            page={paymentsPage}
            perPage={paymentsPerPage}
            dataLoading={dataPaymentLoading}
            exportFunc={onExportPayments}
            getItems={getItemsPayments}
        />
        <Spacer size={30}/>
        <PageHeader title={formatMessage(m.traffic)}/>
        <TrafficFilter
            value={trafficFilter}
            setValue={setTrafficFilter}
            options={filterTrafficOptions}
        />
        <TrafficTable
            data={dataTrafficList}
            options={tableTrafficOptions}
            count={trafficCount}
            page={trafficPage}
            perPage={trafficPerPage}
            dataLoading={dataTrafficLoading}
            exportFunc={onExportTraffic}
            getItems={getItemsTraffic}
        />
    </PanelLayout>
};

const mapState = ({payments, references}) => ({
    dataTrafficList: payments.trafficList,

    trafficCount: payments.trafficCount,
    trafficPage: payments.trafficPage,
    trafficPerPage: payments.trafficPerPage,

    dataPaymentList: payments.list,
    
    paymentsCount: payments.paymentsCount,
    paymentsPage: payments.paymentsPage,
    paymentsPerPage: payments.paymentsPerPage,

    dataTrafficLoading: payments.trafficLoading,
    dataPaymentLoading: payments.loading,

    options: references.options["pages"]
});

const mapMethods = {
    getTrafficList,
    getPaymentsList
};

export default withTranslation(withRouter(connect(mapState, mapMethods)(Payments)));