import { useWindowWidth } from "../hooks";

const defaultSizes = {
    mobile: 767,
    tablet: 1024,
    issueWidth: 767
};

export const withMobile = (Component, sizes = defaultSizes) => {
    const ComponentWithMobile = (props) => {
        const windowWidth = useWindowWidth();
        
        const isMobile = windowWidth < (sizes?.mobile || defaultSizes.mobile);
        const isTablet = windowWidth < (sizes?.tablet || defaultSizes.tablet);
        const isIssueWidth = windowWidth < (sizes?.issueWidth || defaultSizes.issueWidth);

        return (
            <Component
                {...props}
                {...{
                    isMobile,
                    isTablet,
                    isIssueWidth
                }}
            />
        );
    }

    return ComponentWithMobile;
};