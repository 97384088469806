import React from "react";
import {connect} from "react-redux";
import styled from 'styled-components';
import {FlexGrid, FlexGridItem} from "./FlexGrid";


const StyledPageHeader = styled.div`
    padding-bottom: 25px;
    padding-top: 10px;
    width: 100%;
    
    h1 {
        font-family: inherit;
        font-weight: 600;
        font-size: 1.3rem;
        line-height: 1.2em;
        color: var(--color-brand1);
    }
`;

const PageHeader = ({title, children, appType, service, serviceDialer, showService = true, extraTitle = null, ...props}) => {
    const currentService = serviceDialer;

    return (<StyledPageHeader
        className="page-header"
        {...props}
    >
        <FlexGrid justify="space-between" align="middle">
            {title &&
                <FlexGridItem><h1>{title}</h1></FlexGridItem>
            }
            {extraTitle &&
                <FlexGridItem><h1>{extraTitle}</h1></FlexGridItem>
            }
            <FlexGridItem>{children}</FlexGridItem>
        </FlexGrid>
    </StyledPageHeader>)
};

const mapState = ({auth}) => ({
    appType: auth.appType,
    service: auth.service,
    // serviceDialer: auth_dialer.serviceDialer
});

export default connect(mapState, {})(PageHeader);