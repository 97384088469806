import {
    DEFAULT_NOT_AUTH_PATH,
    DEFAULT_AUTH_PATH,

    SET_AUTH,
    SET_SERVICE,
    SET_INFO_ACCOUNT,
    SET_LOADING_INFO,
    SET_MAINTENANCE_MODE,
    SET_USER_LOCALIZATION,

    // local storage
    LOCAL_STORAGE_SESSION_EXPIRED
} from "../const";
import {login, logout} from "../api/auth";
import {createAction} from  ".";
import history from "../config/history";
import {api} from "../api/loginRoutes";
import { Message } from "rsuite";


const setLoading = createAction(SET_LOADING_INFO);
const setInfoAccount = createAction(SET_INFO_ACCOUNT);
const setUserLocals = createAction(SET_USER_LOCALIZATION);
const setMaintenanceModeAction = createAction(SET_MAINTENANCE_MODE);
export const setServiceAction = createAction(SET_SERVICE);

export const setAuth = (payload) => ({type: SET_AUTH, payload});

export const setMaintenanceMode = (value) => (dispatch) => {
    dispatch( setMaintenanceModeAction(value) );
    if (value) {
        localStorage.removeItem("sessionId");
        dispatch( setAuth(false) );
        dispatch( setInfoAccount(null) );
    }
};



export const loginUser = ({lang = "en", loginHistory, loginLocation, ...data}) => (dispatch) => {
    dispatch(setLoading(true));

    return login(data)
        .then((response) => {
            if (!response || response.response && (response.response.status || !response.response.data)) {
                if (response.response.data.message) {
                    window.toaster.push(<Message showIcon type="error">
                        {response.response.data.message}
                    </Message>, {duration: 6500})
                }

                return;
            }
            const sessionId = response.data.session_id || null;

            // const secondAuthType = result.second_authentication_type;

            const sessionLang = lang || "en";
            const sessionRtl = sessionLang === "ar";

            localStorage.setItem("lang", sessionLang);
            localStorage.setItem("rtl", sessionRtl);
            localStorage.setItem("sessionId", sessionId);

            dispatch( setAuth(true) );
            dispatch( setUserLocals({lang: sessionLang, rtl: sessionRtl}) );

            return response;

        })
        .catch((error) => {
            dispatch(setAuth(false));

            const reasonCode = error?.response?.data?.error?.reason_code;
            if (!reasonCode) {
                // Alert.error(error?.response?.data?.error?.data?.error_type || DEFAULT_ERROR_MESSAGE);
            }

            return error;
            // return Promise.reject(error);

        })
        .finally(() => {
            localStorage.removeItem(LOCAL_STORAGE_SESSION_EXPIRED);
            // const location = !loginRecently || (!loginRecently && logoutRecently) ? history.location : loginLocation;
            // // !logoutRecently 
            // const isSecondAuth = JSON.parse(sessionStorage.getItem("secondAuth"));
 
            // if (!isSecondAuth) {
            //     extraHistoryPush(location.pathname)
            //         .then((path) => {
            //             history.replace("/reload");
            //             history.push({
            //                 pathname: DOMMatrixReadOnlyEFAULT_AUTH_PATH
            //             });
            //         })
            //         .catch(() => {
            //             console.log("Login")
            //         })
            //         .finally(() => {
            //             // history.goBack();
            //             history.replace("/reload");
            //             history.push({
            //                 pathname: DEFAULT_AUTH_PATH
            //             });
            //             localStorage.removeItem(LOCAL_STORAGE_SESSION_EXPIRED);
            //         })
            // }
            
            // history.push({
            //     pathname: DEFAULT_AUTH_PATH
            // });
            dispatch(setLoading(false));
            // sessionStorage.removeItem("logout_recently");
            // sessionStorage.setItem("login_recently", true);
            // sessionStorage.removeItem("secondAuth");
        })
};

export const loginBySession = (sessionId, callback=null) => (dispatch) => {

    localStorage.setItem("lang", "en");
    localStorage.setItem("rtl", false);
    localStorage.setItem("sessionId", sessionId);
    localStorage.removeItem(LOCAL_STORAGE_SESSION_EXPIRED);

    dispatch( setAuth(true) );

    dispatch(setUserLocals({lang: "en", rtl: false}))

    if (callback) {
        callback();
    }
};

export const logoutUser = () => async (dispatch) => {
    await logout();

    localStorage.removeItem("sessionId");
   
    dispatch( setAuth(false) );
    history.push({
        pathname: DEFAULT_NOT_AUTH_PATH
    });
};


export const ping = () => async (dispatch) => {
    const res = await api("ping");

    if (res instanceof Error || !res.ip)
        return false;

    dispatch( setMaintenanceModeAction(false) );
    return true;
};


export const changeLanguage = (lang, rtl = false) => (dispatch) => {
    dispatch( setUserLocals({lang, rtl}) );
};