import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import PanelLayout from "../../components/Override/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import Title from "../../components/Override/Title";

import LiveCallsFilter from "./LiveCallsFilter";
import LiveCallsTable from "./LiveCallsTable";

import styled from "styled-components";
import {withRouter} from "../../hoc/WithRouterHOC";
import {getOptionParams, pipe} from "../../utils";
import {getLiveCallsList} from "../../actions/liveCalls";


const defaultFilterValue = {
    number: null
};

const LiveCalls = ({
    // api data
    dataList,
    // api methods
    getList,
    // stored data
    dataLoading,
    // other data
    header,
    options,
    location
}) => {
    Title(header);

    const filterOptions = getOptionParams(options, location, "filters");
    const tableOptions = getOptionParams(options, location, "table");

    const [filter, setFilter] = useState(defaultFilterValue);

    const [filteredData, setFilteredData] = useState(dataList);

    const filtersModel = {
        number: (value, filterValue) => value.name.startsWith(filterValue) 
            || value.phone.startsWith(filterValue)
            || value.cli.startsWith(filterValue)
            || !filterValue
    };

    useEffect(() => {
        getList();
    }, [])

    useEffect(() => {
        setFilteredData((data) => {
            if (dataList && dataList.length) {
                return pipe(dataList, filter, filtersModel);
            }
            return data
        });
    }, [dataList, filter]);



    return (<>
        <PanelLayout>
            <PageHeader title={header}></PageHeader>
            <LiveCallsFilter
                value={filter}
                setValue={setFilter}
                disabled={dataLoading}
                options={filterOptions}
            />
            <LiveCallsTable
                data={filteredData}
                options={tableOptions}
            />
        </PanelLayout>
    </>);
};


const mapState = ({liveCalls, references}) => ({
    dataList: liveCalls.list,
    dataLoading: liveCalls.loading,
    options: references.options["pages"]
});

const mapMethods = {
    getList: getLiveCallsList
};

export default withRouter(connect(mapState, mapMethods)(LiveCalls));


const CustomSpacer = styled.div`
    &&& {
        height: 55px;
    }
`;