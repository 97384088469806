import { FlexboxGrid } from "rsuite";
import styled, {css} from "styled-components";

export const FluidSectionFlexboxGrid = styled(FlexboxGrid).attrs(props => {
    return {
        className: props.className || "fluid-flexbox-grid"
    }
})`
    && {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-top: 15px;
        margin-bottom: 15px;
    }
`;

export const StyledItemFitWidth = styled(FlexboxGrid.Item).attrs(props => {
    return {
        className: props.className || "fluid-flexbox-grid-left"
    }
})`
    && {
        flex-grow: 1;
        margin-right: ${props => props.marginRight + "px"};
    }
`;

export const StyledItemAutoMargin = styled(FlexboxGrid.Item).attrs(props => {
    return {
        className: props.className || "fluid-flexbox-grid-right"
    }
})`
    && {
        margin-left: auto;

        > *:not(:last-child) {
            margin-right: 10px;
        }
    }
`;