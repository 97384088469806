import React, {useMemo, useState, useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "../../hoc/WithRouterHOC";
import PanelLayout from "../../components/Override/PanelLayout";
import PageHeader from "../../components/base/PageHeader";
import Title from "../../components/Override/Title";

import TrafficReportTable from "./TrafficReportTable";
import TrafficReportFilter from "./TrafficReportFilter";

import {
    getStringGroup,
    getOptionParams,
    getValuesByKeyList,
    getServiceByLocation,
    objectClearEmptyValues } from "../../utils";
import { transformed_service_reverse_data } from "../../const";

import { getTrafficReportList } from "../../actions/trafficReport";

import {getFormattedDate, toStartDayTime, toEndDayTime} from "../../utils/date";

const defaultFilterValue = {
    date: [toStartDayTime(new Date()), toEndDayTime(new Date())],
    group: ["cli", "phone", "price"]
};

const getFormattedFilter = (filter) => {
    const clearFilter = objectClearEmptyValues(filter);

    const neededValuesFilter = getValuesByKeyList(clearFilter, ["filter_cli", "filter_phone"]);
    const groupValues = getStringGroup(clearFilter.group);

    const formattedFilter = {
        ...neededValuesFilter,
        group: groupValues,
        date_start: getFormattedDate(clearFilter.date[0], "-"),
        date_end: getFormattedDate(clearFilter.date[1], "-")
    };

    return formattedFilter;
};

const TrafficReport = ({
    // api data
    dataList,
    count,
    page,
    perPage,
    // api methods
    getList,
    // stored data
    dataLoading,
    // other data
    header,
    options,
    location
}) => {
    Title(header);

    const tableOptions = getOptionParams(options, location, "table");
    const filterOptions = getOptionParams(options, location, "filter");

    const serviceByLocation = getServiceByLocation(location);

    const [filter, setFilter] = useState(defaultFilterValue);
    const [requestFilter, setRequestFilter] = useState(getFormattedFilter(filter));

    const applyFilters = () => {
        const formattedFilter = getFormattedFilter(filter);
        setRequestFilter(formattedFilter);

        getList(formattedFilter, 1, perPage);
    };
    
    useEffect(() => {
        update();
    }, []);


    const getItems = (_page, _perPage) => {
        getList(requestFilter, _page, _perPage);
    };

    const onExport = () => {
        getList(requestFilter, 1, 0, "traffic-reports.csv");
    };

    const update = () => {
        getList(requestFilter, 1, perPage);
    };

    return <PanelLayout>
        <PageHeader title={header}></PageHeader>
        <TrafficReportFilter
            value={filter}
            setValue={setFilter}
            options={filterOptions}
            disabled={dataLoading}
            applyFilters={applyFilters}
        />
        <TrafficReportTable
            data={dataList}
            count={count}
            page={page}
            perPage={perPage}
            dataLoading={dataLoading}
            options={tableOptions}
            filter={requestFilter}

            exportFunc={onExport}
            getItems={getItems}
        />
    </PanelLayout>
};

const mapState = ({trafficReport, references}) => ({
    dataList: trafficReport.list,
    count: trafficReport.count,
    page: trafficReport.page,
    perPage: trafficReport.perPage,
    dataLoading: trafficReport.loading,
    options: references.options["pages"]
});

const mapMethods = {
    getList: getTrafficReportList
};

export default withRouter(connect(mapState, mapMethods)(TrafficReport));