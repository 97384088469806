import axios from "axios";
import store from "../store";

import {checkVersionAxios, requestAxios, requestAxiosWithHeaders} from ".";
import {APP_VERSION} from "../index";
import {pageIdLogObject} from "../utils/pageIdLogger";

import {baseUrl} from "./index";


const setPageId = () => {
    const currentPage = pageIdLogObject.pageMatch.path;
    const tab = pageIdLogObject.lastTab;

    return !pageIdLogObject.withTab
        ? currentPage
        : `${currentPage}${tab ? '/' + tab : ''}`
};


export const getAppVersion = async () => {
    return await checkVersionAxios.get(`/meta.json?timestamp=${Date.now()}`)
        .then( (response) => response && response.data && response.data.version
            ? response.data
            : null
        )
        .catch( e => null )
};


export const generateApiGetUrl = ({method, path = "", params = {}}) => {
    const strParams = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    return `${baseUrl}/${method}${path?`/${path}`:""}${strParams && '?'}${strParams}`
};


export const generateApiUrl = ({method, path = ""}) => {
    return `${baseUrl}/${method}${path?`/${path}`:""}`
};


export const api = async (method = "", params = {}, processErrorManual, cancelToken, withHeaders = false) => {
    const paramsCloned = {...params};
    const config = {
        headers: {"X-App-Version": APP_VERSION}
    };

    // cancelToken as f argument is deprecated. use params property "cancelToken" instead
    if (cancelToken) {
        config.cancelToken = cancelToken.token;
    }

    if (paramsCloned.cancelToken) {
        config.cancelToken = paramsCloned.cancelToken.token;
        delete paramsCloned.cancelToken;
    }

    const requestApi = withHeaders ? requestAxiosWithHeaders : requestAxios;

    return requestApi.request({ 
        method: "post",
        url: generateApiGetUrl({method, params: paramsCloned}),
        ...config
    })
    .then(response => {
        if (axios.isCancel(response))
            return {...response, cancelled: true};

        // const result = _.get(response, 'data.result', {});
        const result = response?.data || {};

        return response && response.data ? result : {};
    })
        .catch((error) => {
            if (error && !error.response) {
                return error;
            }

            return error.response.data;
        })
};



export const getFileResponse = async (method, params, type="csv") => {
    const paramsCloned = {...params, csv: 1};
    const config = {
        responseType: type,
        headers: {
            "X-App-Version": APP_VERSION,
        }
    };

    return await requestAxios.request({ 
        method: "post",
        url: generateApiGetUrl({method, params: paramsCloned}),
        ...config
    })
    .then( response => (response && response.data) )
    .catch( e => null )
};




export const apiWithHeaders = (method, params) => {

    return requestAxiosWithHeaders.post(
        generateApiUrl({method, path: "/headers"}),
        {
            params,
            page_id: setPageId(),
            id: null
        },
        {headers: {
                "X-App-Version": APP_VERSION,
                httpAgent: 123
            }})
        .then(response => (response && response.data && response.data.result) || {})
        .catch(e => e)
};


