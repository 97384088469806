import {combineReducers} from "redux";
import cdr from "./cdr";
import auth from "./auth";
import numbers from "./numbers";
import payments from "./payments";
import liveCalls from "./liveCalls";
import references from "./references";
import accessList from "./accessList";
import trafficReport from "./trafficReport";

export default combineReducers({
    cdr,
    auth,
    numbers,
    payments,
    liveCalls,
    references,
    accessList,
    trafficReport
});