import React, {useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {Navbar, Sidenav, Sidebar, Nav} from "rsuite";
import CogIcon from "@rsuite/icons/legacy/Cog";
import PhoneSqaure from "@rsuite/icons/legacy/PhoneSquare";
import EnvelopeSquare from "@rsuite/icons/legacy/EnvelopeSquare";
import Money from "@rsuite/icons/legacy/Money";

import AngleLeftIcon from "@rsuite/icons/legacy/AngleLeft";
import AngleRightIcon from "@rsuite/icons/legacy/AngleRight";

import UserInfo from "../UserInfo";

import {withRouter} from "../../hoc/WithRouterHOC";

import {useWindowWidth} from "../../hooks";


import {ScreenPositions} from "../../const";
import {checkPermissionsFor} from "../../store/storeHelpers";
import {getOption} from "../../utils";

import styled, {css} from "styled-components";
import { FlexGrid } from "../base/FlexGrid";

import { withTranslation } from "../../hoc/WithTranslation";
import { withMobile } from "../../hoc/WithMobile";


const SideMenu = ({
    expand: baseExpand,
    menuText,

    balance,
    activeKey,
    permissions,
    collapsible,
    screenPosition,

    onSelect,
    setExpand,
    logoutUser,

    isMobile,

    m,
    formatMessage,

    ...props
}) => {

    const menuPageList = [
        {key: null, title: menuText.voice.title, icon: <PhoneSqaure/>,
            children: [
                {key: "/live-calls", title: menuText.liveCalls, checkedMethods: []},
                {key: "/my-numbers-voice", title: menuText.voice.myNumbers, checkedMethods: []},
                {key: "/traffic-report-voice", title: menuText.voice.trafficReport, checkedMethods: []},
                {key: "/cdr", title: menuText.voice.cdr, checkedMethods: []},
                {key: "/access-list-voice", title: menuText.voice.accessList, checkedMethods: []},
            ]
        },
        {key: null, title: menuText.sms.title, hide: true, icon: <EnvelopeSquare/>,
            children: [
                {key: "/my-numbers-sms", title: menuText.sms.myNumbers, checkedMethods: []},
                {key: "/traffic-report-sms", title: menuText.sms.trafficReport, checkedMethods: []},
                {key: "/mdr", title: menuText.sms.cdr, checkedMethods: []},
                {key: "/access-list-sms", title: menuText.sms.accessList, checkedMethods: []},
            ]
        },
        {key: "/payments", title: menuText.payments, checkedMethods: [], icon: <Money/>}

        // {key: "/payments", title: intl.formatMessage(localMessages.payments), hide: true, checkedMethods: [PAYMENT_REQUEST_LIST_API]},
    ];

    const menuData = useMemo(() => {
        const filterMenuItems = (items) => (
            items.reduce((sum, item) => {
                const children = (item.children && item.children.length)
                    ? filterMenuItems(item.children)
                    : null;
                
                if (item.hide) {
                    return sum;
                }

                if (item.checkedMethods) {
                    for (const method of item.checkedMethods) {
                        if (!checkPermissionsFor(method))
                            return sum;
                    }
                }

                return [
                    ...sum,
                    {
                        ...item, 
                        children
                    }
                ];
            }, [])
        );

        return filterMenuItems(menuPageList);
    }, [permissions]);

    const onSignOut = () => {
        logoutUser();
    };

    const leftToRight = screenPosition === ScreenPositions.LEFT;

    const expand = isMobile ? false : baseExpand;

    return (<OverrideSidebar
        className="override-menu-sidebar"
        expand={expand}
        pullRight={!leftToRight}
        placement="right"
    >
        <Sidenav expanded={expand} appearance="subtle">
            <Sidenav.Body>
                <UserInfo
                    expand={expand}
                    balance={balance}
                    leftToRight={leftToRight}
                />

                <Nav>
                {menuData.map(item => {
                    const itemContent = <>{item.title}</>;

                    if (item.children) {
                        if (item.children.length) {
                            return (
                                <NavMenu 
                                    collapsible={collapsible}
                                    item={item}
                                />
                            )
                        } else {
                            return null;
                        }
                    }

                    return <NavLink
                            key={item.key}
                            to={item.key}
                            icon={item.icon}
                            {...item}
                        >
                            {itemContent}
                        </NavLink>
                    })}
                </Nav>
            </Sidenav.Body>
        </Sidenav>
        <NavToggle
            expand={expand}
            leftToRight={leftToRight}
            onChange={() => setExpand(!expand)}
            onSignOut={onSignOut}
            {...{
                m,
                formatMessage,
                isMobile
            }}
        />
    </OverrideSidebar>)
};

export default withMobile(withTranslation(SideMenu));


const NavToggle = ({ expand, leftToRight, onChange, onSignOut, m, formatMessage, isMobile }) => {
    const cogIconStyle = { width: 20, height: 20 };
    const arrowIconStyle = { width: 20, height: 20, fontSize: 20 };
    return (
        <Navbar appearance="subtle" className="nav-toggle">
            <Nav pullRight={!leftToRight}>
                <Nav.Menu
                    className="sign-out"
                    noCaret
                    placement={leftToRight ? "topStart" : "leftStart"}
                    trigger="click"
                    title={<CogIcon style={cogIconStyle} size="sm" />}
                >
                    <Nav.Item onClick={onSignOut}>{formatMessage(m.signOut)}</Nav.Item>
                </Nav.Menu>
            </Nav>
            <Nav pullRight={leftToRight}>
                {!isMobile && <Nav.Item onClick={onChange} style={{ width: 56, display: "flex", justifyContent: "center" }}>
                    {leftToRight
                        ? expand ? <AngleLeftIcon style={arrowIconStyle}/> : <AngleRightIcon style={arrowIconStyle}/>
                        : expand ? <AngleRightIcon style={arrowIconStyle}/> : <AngleLeftIcon style={arrowIconStyle}/>
                    }
                </Nav.Item>}
            </Nav>
        </Navbar>
    );
};

const NavMenu = withRouter(({item, location, collapsible}) => {
    const subMenuActivePage = item.children.find(item => item.key === location.pathname);

    if (item.children && !item.children.length) {
        return null;
    }

    const [menuOpened, setMenuOpened] = useState(!!subMenuActivePage);
    
    return (
        <Nav.Menu
            key={item.key}
            // isActive={!!submenuActivePage}
            // activeKey={location.pathname}
            noCaret={!collapsible}
            onToggle={(value) => {
                setMenuOpened(value);
            }}
            activeKey={location.pathname}
            title={item.title}
            icon={item.icon}
        >
            {item.children.map(subItem => {
                return <NavLink
                    // componentClass={Link}
                    to={subItem.key}
                    eventKey={subItem.key}
                    // onClick={subItem.onClick || null}
                >
                    {subItem.title}
                </NavLink>
            })}
        </Nav.Menu>
    );
});

const NavLink = withRouter((
    {
        location,
        ...props
    }
) => {

    const navItem = <Nav.Item
        as={Link}
        active={location.pathname.indexOf(props.to) === 0}
        {...props}
    />;

    return navItem;
});

const OverrideSidebar = styled(Sidebar)`
    && {
        display: flex;
        /* flex: 0 !important; */
        flex: 0 0 ${props => props.expand ? "300px" : "56px"} !important;
        flex-direction: column;
        justify-content: space-between;
        width: ${props => props.expand ? "300px" : "56px"} !important;
    }

    && .rs-sidenav-nav > .rs-sidenav-item > .rs-icon,
    && .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle
    > .rs-icon:not(.rs-dropdown-toggle-caret) {
        left: 24px;
        top: 18px;
        color: var(--color-sidebar-color);
    }
    && .rs-icon {
        left: 24px;
        top: 18px;
        color: var(--color-sidebar-color);
    }
    && .rs-navbar-subtle .rs-navbar-item:hover, 
    && .rs-sidenav-subtle.rs-sidenav-collapse-out .rs-dropdown.rs-dropdown-selected-within .rs-dropdown-toggle:hover,
    && .rs-navbar-subtle .rs-navbar-item:focus,
    && .rs-nav-default .rs-nav-item:focus,
    && .rs-sidenav-subtle .rs-sidenav-item:focus-visible,
    && .rs-sidenav-subtle .rs-dropdown-toggle:focus-visible {
        background: var(--color-menu-background-active-light);
        background-color: var(--color-menu-background-active-light);
    }
    && .rs-dropdown.rs-dropdown-expand ul.rs-dropdown-menu.rs-dropdown-menu-collapse-in, 
    && .rs-dropdown.rs-dropdown-expand ul.rs-dropdown-menu.rs-dropdown-menu-collapse-out,
    && .rs-dropdown.rs-dropdown-collapse ul.rs-dropdown-menu.rs-dropdown-menu-collapsing {
        background-color: transparent;
        background: transparent;
    }

    && .rs-dropdown:not(.rs-dropdown-expand) ul.rs-dropdown-menu {
        background-color: var(--color-menu-dropdown-background-light);
        padding: 0;
    }

    && .rs-dropdown.rs-dropdown-submenu ul.rs-dropdown-menu li a.rs-dropdown-item.rs-dropdown-item-active {
        color: var(--color-text-color-active);
        background-color: var(--color-menu-background-active-light);
    }

    && .rs-dropdown.rs-dropdown-submenu ul.rs-dropdown-menu li a.rs-dropdown-item:not(.rs-dropdown-item-active) {
        color: var(--color-text-color-dark);
    }

    && .rs-dropdown.rs-dropdown-submenu .rs-dropdown-menu:not([hidden]) {
        ${props => props.pullRight ? css`
            right: 0;
            left: inherit;
            display: block;
        ` : css`
            left: 0;
            right: inherit;
            display: block;
        `}
    }

    && .rs-dropdown .rs-dropdown-header {
        background-color: var(--color-menu-dropdown-background-dark);
        color: var(--color-text-color-active);
        text-align: center;
        font-weight: 700;
        border-bottom: none;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    && .rs-navbar-nav.rs-nav a {
        color: var(--color-text-color-light);
    }

    && .rs-navbar-nav .sign-out a {
        z-index: 100;
    }
`;
