import React from "react";
import { SelectPicker, CheckPicker, TreePicker, DatePicker, DateRangePicker } from "rsuite";
import { FieldInput } from "../CustomFormComponents";
import { ScreenPositions } from "../../const";

import Button from "./Button";
import { withTranslation } from "../../hoc/WithTranslation";




const OverrideForm = ({
    fields,
    component: Component,
    options,
    children,
    disabled,
    overrideClassName,

    applyFunc,
    applyButton,

    isMobile,
    isTablet,
    isIssueWidth,

    m,
    formatMessage,
    ...props
}) => {
    const positionRight = props.position === ScreenPositions.RIGHT;

    const isAdaptive = isMobile || isTablet || isIssueWidth;

    const sortFields = (fields, order) => {
        const fieldNameList = fields.map(field => field.name);
        const orderNameList = order.filter(name => fieldNameList.includes(name));

        return fields.sort((a, b) => {
            return orderNameList.indexOf(a.name) - orderNameList.indexOf(b.name);
        });
    };

    const renderFields = (fields) => {
        return <>
            {fields.map((fieldProps) => {

                const pickerTypeList = [SelectPicker, CheckPicker, TreePicker, DatePicker, DateRangePicker];
                const isPicker = fieldProps.accepter && pickerTypeList.includes(fieldProps.accepter);
                const placement = isPicker && positionRight ? "bottomEnd" : "bottomStart";

                return <FieldInput 
                    {...{
                        ...fieldProps,
                        placement
                    }}
                />                
            })}
        </>
    };

    const filteredFormFields = options && "hide" in options ? fields.filter((field) => !options.hide.includes(field.name)) : fields;
    const sortedFields = options && "order" in options ? sortFields(filteredFormFields, options.order) : filteredFormFields;
    const renderedFields = renderFields(sortedFields);

    return <Component className={overrideClassName}
        {...{
            ...props,
            isAdaptive,
            isMobile,
            isTablet,
            isIssueWidth
        }}
    >
        {renderedFields}
        {children}
        {applyButton ? <Button
            className="apply-button"
            buttonStyle="default"
            disabled={disabled}
            onClick={applyFunc}
        >
            {formatMessage(m.applyFilter)}
        </Button> : <></>}
    </Component>
};


export default withTranslation(OverrideForm);