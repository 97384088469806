import {
    DEFAULT_SP_KEY,
    SMS_DEFAULT_SP_KEY,
    DEFAULT_PRICE_KEY,
    SMS_DEFAULT_PRICE_KEY,
    transformed_service_data,

    // actions
    BALANCE_SET_LOADING,
    BALANCE_SET_LIST,
    OVERRIDE_SET_OPTIONS
} from "../const/";
import { mergeDeep, getServiceByLocation } from "../utils/helpers";
import history from "../config/history";

const initialState = {
    loading: false,
    balance: {},
    options: {},

    serviceByLocation: getServiceByLocation(history.location),
};

const handlers = {    
    [BALANCE_SET_LOADING]: (state, {payload}) => ({...state, loading: payload}),
    [BALANCE_SET_LIST]: (state, {payload}) => {
        return {
            ...state,
            balance: payload
        }
    },
    [OVERRIDE_SET_OPTIONS]: (state, {payload}) => {
        const mergedOptions = mergeDeep(state.options, payload);
        return {...state, options: mergedOptions}
    },

    DEFAULT: state => state
};

export default  (state = initialState, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state,action)
};
  